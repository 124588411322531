import React from "react";
import { Helmet } from "react-helmet";
import { useState } from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const Live = () => {
  const [currentCat, setCurrentCat] = useState(1);
  const [loaded, setLoaded] = useState(0);
  console.log(loaded);
  return (
    <React.Fragment>
      <Helmet>
        <link rel="canonical" href="https://robowars.co.in/Live" />
        <title>Live | Robowars India</title>
        <meta
          name="description"
          content="Live Combat Robotics event fixtures. Check out the current ongoing robotics competition here"
        />
        <meta
          name="keywords"
          content="Combat Robotics, Robowars, Robowars India, Robotics, Robotics India, Live, Techfest, Techinical Fest, Robotics Community, Robotics India, Robotic, Robots, Battlebots, Featherweight, Heavyweight, Lightweight, Hobbyweight, Bettleweight, Events"
        />
        <meta property="og:title" content="Live | Robowars India" />
        <meta property="og:url" content="https://robowars.co.in/Live" />
        <meta property="og:site_name" content="Robowars India" />
        <meta property="og:type" content="website" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Live | Robowars India" />
        <meta
          name="twitter:description"
          content="Live Combat Robotics event fixtures. Check out the current ongoing robotics competition here"
        />
      </Helmet>
      <Grid
        container
        marginTop={1}
        alignItems="center"
        justifyContent="center"
        display="flex"
      >
        <Paper
          sx={{
            padding: 2,
            margin: "auto",
            maxWidth: "90%",
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === "dark" ? "#1A2027" : "#fff",
          }}
        >
          <Grid
            container
            marginTop={1}
            alignItems="center"
            justifyContent="center"
            display="flex"
          >
            <Grid
              item
              xs={4}
              alignItems="center"
              justifyContent="center"
              display="flex"
              height="50px"
              onClick={() => {
                if (currentCat === 2) {
                  setCurrentCat((currentCat) => 1);
                  setLoaded((loaded) => 0);
                }
              }}
              // sx={{ border: 1, borderColor: "#ff0000" }}
            >
              <Button
                varient="outlined"
                disabled={currentCat === 0 ? true : false}
                fullWidth
                sx={{ height: "45px", padding: "0px" }}
              >
                15kg
              </Button>
            </Grid>
            <Grid
              item
              xs={4}
              justifyContent="center"
              alignItems="center"
              display="flex"
            >
              <Typography varient="h1" fontSize="2rem">
                {currentCat === 1 ? "Techtatva - 15kg" : "Techtatva - 30kg"}
              </Typography>
            </Grid>
            <Grid
              item
              xs={4}
              alignItems="center"
              justifyContent="center"
              display="flex"
              height="50px"
              onClick={() => {
                if (currentCat === 1) {
                  setCurrentCat((currentCat) => 2);
                  setLoaded((loaded) => 0);
                }
              }}
              // sx={{ border: 1, borderColor: "#ff0000" }}
            >
              <Button
                varient="outlined"
                disabled={currentCat === 0 ? true : false}
                fullWidth
                sx={{ height: "45px", padding: "0px" }}
              >
                30kg
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12} width="100%" maxWidth="100%" marginTop={2}>
            {loaded === 0 ? (
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={!loaded}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            ) : null}
            <iframe
              src={
                currentCat === 1
                  ? "https://challonge.com/5462z6fu/module"
                  : "https://challonge.com/a703pa7k/module"
              }
              title="Brackets"
              width="100%"
              height="550"
              frameborder="0"
              onLoad={() => {
                setLoaded((loaded) => 1);
              }}
              scrolling="auto"
              allowtransparency="true"
            ></iframe>
          </Grid>
        </Paper>
      </Grid>
    </React.Fragment>
  );
};

export default Live;
