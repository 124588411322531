import React from "react";
import Helmet from "react-helmet";
import { useEffect, useState } from "react";
import "../styles.css";
import { db } from "../firebase";

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import ViewTableComponent from "../components/viewTableComponent";

function EventView() {
  var now = new Date(new Date().toUTCString());
  const [blogs, setBlogs] = useState([]);
  const [upcoming, setUpcoming] = useState(1);
  const [loaded, setLoaded] = useState();
  const fetchBlogs = async () => {
    setBlogs([]);
    const response = db
      .collection("Events")
      .where("endDate", upcoming ? ">" : "<=", now)
      .orderBy("endDate", upcoming ? "asc" : "desc");
    const data = await response.get();
    data.docs.forEach((item) => {
      setBlogs((blogs) => [...blogs, item.data()]);
    });
    setLoaded(1);
  };

  //Use Effect function for async
  useEffect(() => {
    fetchBlogs();
  }, [upcoming]);

  //Initialization for first page view
  if (loaded) {
    return (
      <React.Fragment>
        <Helmet>
          <link rel="canonical" href="https://robowars.co.in" />
          <title>Robowars India</title>
          <meta
            name="description"
            content="Robowars Community India. Contains all event and other details regarding Robowars Community project driven by Passion"
          />
          <meta property="og:title" content="Robowars India" />
          <meta property="og:url" content="https://robowars.co.in/" />
          <meta property="og:site_name" content="Robowars India" />
          <meta property="og:type" content="website" />
          <meta
            name="keywords"
            content="Combat Robotics, Robowars, Robowars India, Robotics, Robotics India, Techfest, Techinical Fest, Robotics Community, Robotics India, Robotic, Robots, Battlebots, Featherweight, Heavyweight, Lightweight, Hobbyweight, Bettleweight, Events"
          />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Robowars India" />
          <meta
            name="twitter:description"
            content="Robowars Database. Community project driven by Passion"
          />
        </Helmet>
        <Grid container spacing={2} marginTop={2} alignItems="center">
          <Grid container spacing={0} alignItems="center">
            <Grid item xs={1} />
            <Grid
              item
              xs={4}
              alignItems="center"
              justifyContent="center"
              display="flex"
              height="50px"
              onClick={() => {
                if (upcoming !== 1) {
                  setUpcoming((upcoming) => 1);
                  setLoaded(0);
                }
              }}
              sx={{ border: 1, borderColor: "#ff0000" }}
            >
              <Button
                varient="outlined"
                disabled={upcoming === 0 ? true : false}
                fullWidth
                sx={{ height: "45px", padding: "0px" }}
              >
                Upcoming
              </Button>
            </Grid>
            <Grid item xs={2} />
            <Grid
              item
              xs={4}
              alignItems="center"
              justifyContent="center"
              display="flex"
              height="50px"
              onClick={() => {
                if (upcoming !== 0) {
                  setUpcoming((upcoming) => 0);
                  setLoaded(0);
                }
              }}
              sx={{ border: 1, borderColor: "#ff0000" }}
            >
              <Button
                varient="outlined"
                disabled={upcoming === 1 ? true : false}
                fullWidth
                sx={{ height: "45px", padding: "0px" }}
              >
                Past
              </Button>
            </Grid>
            <Grid item xs={1} />
          </Grid>
          <Grid item xs={12} alignItems="center">
            <ViewTableComponent
              DataObj={blogs}
              key={upcoming ? "key1" : "key2"}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  } else {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={!loaded}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
}

export default EventView;
