import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
//import App from "./App";
import Robot from "./pages/robot";
import Submit from "./pages/submit";
import NavBar from "./components/NavBar";
import Teams from "./pages/teams";
import RoboDetails from "./pages/roboDetails";
import TeamDetails from "./pages/teamdetails";
import EventDetails from "./pages/eventdetails";
import Live from "./pages/live";
import EventView from "./pages/events_list";
import Leaderboard from "./pages/leaderboard";
import HomeView from "./pages/home_view";
import { inject } from "@vercel/analytics";

// Temporarily down
import Rulebook from "./pages/rulebook";

import { BrowserRouter, Route, Routes } from "react-router-dom";
inject();
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  //<React.StrictMode>
  <BrowserRouter>
    <NavBar />
    <Routes>
      <Route exact path="/" element={<HomeView />} />
      <Route path="/Events" element={<EventView />} />
      <Route path="/Robots" element={<Robot />} />
      <Route path="/Teams" element={<Teams />} />
      <Route path="/Submit" element={<Submit />} />
      <Route path="/Live" element={<Live />} />
      <Route path="/Leaderboard" element={<Leaderboard />} />
      <Route path="/Robots/:id" element={<RoboDetails />} />
      <Route path="/Teams/:id" element={<TeamDetails />} />
      <Route path="/Event/:id" element={<EventDetails />} />
      <Route path="/Rulebook" element={<Rulebook />} />
    </Routes>
  </BrowserRouter>
  //</React.StrictMode>
);
