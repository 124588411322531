import firebase from "firebase/app";
import { getFirestore } from "firebase/firestore";
import "firebase/firebase-storage";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FB_PROJ_ID,
  storageBucket: process.env.REACT_APP_FB_STR_BUCK,
  messagingSenderId: process.env.REACT_APP_FB_MESSAGE,
  appId: process.env.REACT_APP_FB_API_ID,
};

const app = firebase.initializeApp(firebaseConfig);
//const storageRef = firebase.storage().ref();

export const db = firebase.firestore(app);
