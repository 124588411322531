import React from "react";
import { Helmet } from "react-helmet";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Link from "@mui/material/Link";
import { styled } from "@mui/material/styles";

//Icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Submit = () => {
  return (
    <React.Fragment>
      <Helmet>
        <link rel="canonical" href="https://robowars.co.in/Submit" />
        <title>Submit | Robowars India</title>
        <meta
          name="description"
          content="Submit your robot or event details here. Only applicable for Combat Robotics"
        />
        <meta
          name="keywords"
          content="Combat Robotics, Robowars, Robots, Combat Robots, Robowars Robots, Robowars India, Robotics, Robotics India, Live, Techfest, Techinical Fest, Robotics Community, Robotics India, Robotic, Robots, Battlebots, Featherweight, Heavyweight, Lightweight, Hobbyweight, Bettleweight, Events"
        />
        <meta property="og:title" content="Submit | Robowars India" />
        <meta property="og:url" content="https://robowars.co.in/Submit" />
        <meta property="og:site_name" content="Robowars India" />
        <meta property="og:type" content="website" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Submit | Robowars India" />
        <meta
          name="twitter:description"
          content="Submit Robot or Combat Robotics event details"
        />
      </Helmet>
      <Grid
        container
        marginTop={2}
        alignItems="center"
        sx={{ display: { xs: "none", md: "flex" } }}
      >
        <Grid item xs={2} />
        <Grid item xs={8}>
          <Paper
            sx={{
              padding: 2,
              margin: "auto",
              maxWidth: 900,
              flexGrow: 1,
              backgroundColor: (theme) =>
                theme.palette.mode === "dark" ? "#1A2027" : "#fff",
            }}
          >
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              sx={{ display: { xs: "none", md: "flex" } }}
            >
              <Grid
                item
                xs={12}
                justifyContent="center"
                alignItems="center"
                display="flex"
              >
                <Typography varient="h1" fontSize="4rem">
                  Hey
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              display="flex"
              marginTop={2}
            >
              <Typography varient="h1" fontSize="1rem" align="justify">
                This is a community driven project to maintain all the data
                related to Robowars scene in India for easier access and
                encourage new people to join this sports.
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              display="flex"
              marginTop={2}
            >
              <Typography varient="h1" fontSize="1rem" align="justify">
                Currently we are running a beta version of this website to get
                things going as well as stress test for our end-goals. We will
                update the website as time passes by. This will be a long term
                project and we hope all teams and university do support this
                initiative.
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              display="flex"
              marginTop={2}
            >
              <Accordion>
                <AccordionSummary
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography variant="h4">For Teams,</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    varient="h1"
                    fontSize="1rem"
                    align="justify"
                    width="650px"
                  >
                    If you are a Robowars team, please update the following
                    details through the form link located below or by sending an
                    email to our official mail.
                    <div>
                      <li>Team Name</li>
                      <li>Members</li>
                      <li>Foundation Date</li>
                      <li>Team logo - 1:1 Aspect Ratio Image</li>
                      <li>Robot Details</li>
                      <li>Team Photo</li>
                    </div>
                    <Link
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://docs.google.com/forms/d/e/1FAIpQLSde0pJTzvnjIsEOSBbrYbxbJuwkeL_86kQVZKm0Cf9Lv0boWw/viewform?usp=sf_link"
                    >
                      Click here for the form
                    </Link>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              display="flex"
              marginTop={2}
            >
              <Accordion>
                <AccordionSummary
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography variant="h4">For Event organizer,</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    varient="h1"
                    fontSize="1rem"
                    align="justify"
                    width="650px"
                  >
                    If you are an Event organizer, please update the following
                    details through the form link located below or by sending an
                    email to our official mail.
                    <div>
                      <li>Event Name</li>
                      <li>Hosting University</li>
                      <li>Prize Money with bifurication</li>
                      <li>Event Banner - 1:1 Aspect Ratio Image</li>
                      <li>Gurantee Letter</li>
                      <li>Arena Provider</li>
                      <li>Judges Details</li>
                    </div>
                    We put a lot of effort, investment, research into making our
                    robots for a competition and we do expect standards to be
                    maintained at every event. Hence, this is bare minimum
                    information we want from you. <br />
                    <Link
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://docs.google.com/forms/d/e/1FAIpQLSetd5WcQEF6yecHPgtnlZtYGNrcSspEUrTbgc1Qxqe-h9envQ/viewform?usp=sf_link"
                    >
                      Click here for the form
                    </Link>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              display="flex"
              marginTop={2}
            >
              <Typography varient="h1" fontSize="3rem" align="justify">
                Contact Details
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              display="flex"
              marginTop={2}
            >
              <Typography varient="h1" fontSize="2rem" align="justify">
                robowarsindia.site@gmail.com
              </Typography>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={2} />
      </Grid>

      {/* Mobile Varient*/}

      <Grid
        container
        marginTop={2}
        alignItems="center"
        sx={{ display: { xs: "flex", md: "none" } }}
      >
        <Grid item xs={12}>
          <Paper
            sx={{
              padding: 2,
              margin: "auto",
              maxWidth: 900,
              flexGrow: 1,
              backgroundColor: (theme) =>
                theme.palette.mode === "dark" ? "#1A2027" : "#fff",
            }}
          >
            <Grid container alignItems="center" justifyContent="center">
              <Grid
                item
                xs={12}
                justifyContent="center"
                alignItems="center"
                display="flex"
              >
                <Typography varient="h1" fontSize="4rem">
                  Hey
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              display="flex"
              marginTop={2}
            >
              <Typography varient="h1" fontSize="1rem" align="justify">
                This is a community driven project to maintain all the data
                related to Robowars scene in India for easier access and
                encourage new people to join this sports.
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              display="flex"
              marginTop={2}
            >
              <Typography varient="h1" fontSize="1rem" align="justify">
                Currently we are running a beta version of this website to get
                things going as well as stress test for our end-goals. We will
                update the website as time passes by. This will be a long term
                project and we hope all teams and university do support this
                initative.
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              display="flex"
              marginTop={2}
            >
              <Accordion>
                <AccordionSummary
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography variant="h4">For Teams,</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography varient="h1" fontSize="1rem" align="justify">
                    If you are a Robowars team, please update the following
                    details through the form link located below or by sending an
                    email to our official mail.
                    <div>
                      <li>Team Name</li>
                      <li>Members</li>
                      <li>Foundation Date</li>
                      <li>Team logo - 1:1 Aspect Ratio Image</li>
                      <li>Robot Details</li>
                      <li>Team Photo</li>
                    </div>
                    <Link
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://docs.google.com/forms/d/e/1FAIpQLSde0pJTzvnjIsEOSBbrYbxbJuwkeL_86kQVZKm0Cf9Lv0boWw/viewform?usp=sf_link"
                    >
                      Click here for the form
                    </Link>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              display="flex"
              marginTop={2}
            >
              <Accordion>
                <AccordionSummary
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography variant="h4">For Event organizer,</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography varient="h1" fontSize="1rem" align="justify">
                    If you are an Event organizer, please update the following
                    details through the form link located below or by sending an
                    email to our official mail.
                    <div>
                      <li>Event Name</li>
                      <li>Hosting University</li>
                      <li>Prize Money with bifurication</li>
                      <li>Event Banner - 1:1 Aspect Ratio Image</li>
                      <li>Gurantee Letter</li>
                      <li>Arena Provider</li>
                      <li>Judges Details</li>
                    </div>
                    We put a lot of effort, investment, research into making our
                    robots for a competition and we do expect standards to be
                    maintained at every event. Hence, this is bare minimum
                    information we want from you. <br />
                    <Link
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://docs.google.com/forms/d/e/1FAIpQLSetd5WcQEF6yecHPgtnlZtYGNrcSspEUrTbgc1Qxqe-h9envQ/viewform?usp=sf_link"
                    >
                      Click here for the form
                    </Link>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              display="flex"
              marginTop={2}
            >
              <Typography varient="h1" fontSize="2.5rem" align="justify">
                Contact Details
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              display="flex"
              marginTop={2}
            >
              <Typography varient="h1" fontSize="1.5rem" align="justify">
                robowarsindia.site@gmail.com
              </Typography>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Submit;
