import * as React from "react";
import { Helmet } from "react-helmet";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import "./robotDetails.css";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import robots_list from "../database/robots";
import matches_list from "../database/match";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function FetchDetails() {
  let data = fetchAllMatches(15);
  //console.log(data);
  return data;
}

//Replace this if-else section with foreach() function which will
//scan through the entire data set and fix the value on hit.

function fetchAllMatches(cat) {
  let temp = [];
  let result = [];
  matches_list.forEach((entry) => {
    if (temp.includes(entry.winner)) {
      result[temp.indexOf(entry.winner)].wins += 1;
      result[temp.indexOf(entry.winner)].match += 1;
    } else {
      result.push({
        name: entry.winner,
        team: entry.winner_team,
        wins: 0,
        lose: 0,
        match: 0,
      });
      temp.push(entry.winner);
      result[result.length - 1].wins += 1;
      result[result.length - 1].match += 1;
    }
    if (temp.includes(entry.loser)) {
      result[temp.indexOf(entry.loser)].lose += 1;
      result[temp.indexOf(entry.loser)].match += 1;
    } else {
      result.push({
        name: entry.loser,
        team: entry.loser_team,
        wins: 0,
        lose: 0,
        match: 0,
      });
      temp.push(entry.loser);
      result[result.length - 1].lose += 1;
      result[result.length - 1].match += 1;
    }
  });
  //console.log(temp);
  //console.log(result);
  result.sort((a, b) => {
    return b.wins / b.match - a.wins / a.match || b.match - a.match;
  });
  return result;
}
//Replace the same with above said

function check_existence(bot_name) {
  for (let i = 0; i < robots_list.length; ++i) {
    if (robots_list[i]["name"] === bot_name) {
      return robots_list[i]["team_name"];
    }
  }
  return "Unknown";
}

// function returnPoints(match_details, win) {
//   if (match_details === "U" && win === 1) {
//     return 2;
//   } else if (match_details === "F" && win === 1) {
//     return 4;
//   } else if (match_details === "U" && win === 0) {
//     return 0;
//   } else if (match_details === "F" && win === 0) {
//     return 3;
//   } else if (match_details === "L" && win === 1) {
//     return 1;
//   } else {
//     return 0;
//   }
// }

const Leaderboard = () => {
  var info = FetchDetails();
  let i = 1;
  let j = 1;
  return (
    <React.Fragment>
      <Helmet>
        <link rel="canonical" href="https://robowars.co.in/Leaderboard" />
        <title>Leaderboard | Robowars India</title>
        <meta
          name="description"
          content="Leaderboard for the season 2023-2024 competitions. Contains all the matches for the robotics event listed. Robowars Database. Community project driven by Passion"
        />
        <p>Updated on Oct 21st, 2023</p>
        <meta property="og:title" content="Leaderboard | Robowars India" />
        <meta property="og:url" content="https://robowars.co.in/Leaderboard" />
        <meta property="og:site_name" content="Robowars India" />
        <meta property="og:type" content="website" />
        <meta
          name="keywords"
          content="Combat Robotics, Robowars, Robowars India, Robotics, Robotics India, Techfest, Techinical Fest, Robotics Community, Robotics India, Robotic, Robots, Battlebots, Events, Leaderboard, Featherweight, Heavyweight, Lightweight, Hobbyweight, Bettleweight"
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Leaderboard | Robowars India" />
        <meta
          name="twitter:description"
          content="Leaderboard for the season 2023-2024 competitions | Robowars Database. Community project driven by Passion"
        />
      </Helmet>
      <Grid
        container
        marginTop={2}
        alignItems="center"
        sx={{ display: { xs: "none", md: "flex" } }}
      >
        <Grid item xs={2} />
        <Grid item xs={8}>
          <Button
            varient="outlined"
            fullWidth
            sx={{ height: "45px", padding: "0px" }}
          >
            <Typography
              varient="h3"
              noWrap
              fontSize={30}
              fontWeight={3}
              textAlign="center"
            >
              Featherweight
            </Typography>
          </Button>
        </Grid>
        <Grid item xs={2} />
        <Grid item xs={2} />
        <Grid item xs={8} marginTop={3} marginBottom={3}>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: "100%" }}>
              <Table
                sx={{ minWidth: 300 }}
                aria-label="sticky table"
                stickyHeader
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">Rank</StyledTableCell>
                    <StyledTableCell align="left">Name</StyledTableCell>
                    <StyledTableCell align="left">Team</StyledTableCell>
                    <StyledTableCell align="left">Matches</StyledTableCell>
                    <StyledTableCell align="left">Wins</StyledTableCell>
                    <StyledTableCell align="left">Lose</StyledTableCell>
                    <StyledTableCell align="left">Win%</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {info.map((row) => (
                    <StyledTableRow key={row.name}>
                      <StyledTableCell component="th" scope="row">
                        {i++}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <Link
                          to={
                            check_existence(row.name) === "Unknown"
                              ? `/Submit`
                              : `/Robots/${row.name}`
                          }
                          style={{ textDecoration: "none" }}
                        >
                          {check_existence(row.name) === "Unknown"
                            ? row.name.slice(0, 2) + "******"
                            : row.name}
                        </Link>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <Link
                          to={
                            check_existence(row.name) === "Unknown"
                              ? `/Submit`
                              : `/Teams/${row.team}`
                          }
                          style={{ textDecoration: "none" }}
                        >
                          {check_existence(row.name) === "Unknown"
                            ? null
                            : row.team}
                        </Link>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.match}
                      </StyledTableCell>
                      <StyledTableCell align="left">{row.wins}</StyledTableCell>
                      <StyledTableCell align="left">{row.lose}</StyledTableCell>
                      <StyledTableCell align="left">
                        {parseFloat((row.wins / row.match) * 100).toFixed(2)}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
        <Grid item xs={2} />
      </Grid>

      {/* Mobile varient */}

      <Grid
        container
        marginTop={2}
        alignItems="center"
        sx={{ display: { xs: "flex", md: "none" } }}
      >
        <Grid item xs={12}>
          <Button
            varient="outlined"
            fullWidth
            sx={{ height: "45px", padding: "0px" }}
          >
            <Typography
              varient="h3"
              noWrap
              fontSize={15}
              fontWeight={3}
              textAlign="center"
            >
              Featherweight
            </Typography>
          </Button>
        </Grid>
        <Grid item xs={12} marginTop={3} marginBottom={3}>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: "100%" }}>
              <Table
                sx={{ minWidth: 300 }}
                aria-label="sticky table"
                stickyHeader
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">Rank</StyledTableCell>
                    <StyledTableCell align="left">Name</StyledTableCell>
                    <StyledTableCell align="left">Team</StyledTableCell>
                    <StyledTableCell align="left">Matches</StyledTableCell>
                    <StyledTableCell align="left">Wins</StyledTableCell>
                    <StyledTableCell align="left">Lose</StyledTableCell>
                    <StyledTableCell align="left">Win%</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {info.map((row) => (
                    <StyledTableRow key={row.name}>
                      <StyledTableCell component="th" scope="row">
                        {j++}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <Link
                          to={
                            check_existence(row.name) === "Unknown"
                              ? `/Submit`
                              : `/Robots/${row.name}`
                          }
                          style={{ textDecoration: "none" }}
                        >
                          {check_existence(row.name) === "Unknown"
                            ? row.name.slice(0, 2) + "******"
                            : row.name}
                        </Link>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <Link
                          to={
                            check_existence(row.name) === "Unknown"
                              ? `/Submit`
                              : `/Teams/${row.team}`
                          }
                          style={{ textDecoration: "none" }}
                        >
                          {check_existence(row.name) === "Unknown"
                            ? null
                            : row.team}
                        </Link>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.match}
                      </StyledTableCell>
                      <StyledTableCell align="left">{row.wins}</StyledTableCell>
                      <StyledTableCell align="left">{row.lose}</StyledTableCell>
                      <StyledTableCell align="left">
                        {parseFloat((row.wins / row.match) * 100).toFixed(2)}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Leaderboard;
