const matches_list = [
  {
    id: 1,
    loser: "Atherra",
    loser_team: "DOT Robotics",
    winner_team: "Synergy",
    winner: "Synergy",
    round: "U11",
    event_id: 1,
    event_name: "Gravitas2023",
    video_url: "youtube.com",
    event_array: ["Gravitas", "VIT Vellore", 2023],
    description: "Upper Bracket Round 1",
    category: 15,
  },
  {
    id: 2,
    loser: "Mr. Gator",
    loser_team: "White Shadow",
    winner_team: "Phoenix Bangalore",
    winner: "Titan Blade",
    round: "U12",
    event_id: 1,
    event_name: "Gravitas2023",
    video_url: "youtube.com",
    event_array: ["Gravitas", "VIT Vellore", 2023],
    description: "Upper Bracket Round 1",
    knockout: 1,
    category: 15,
  },
  {
    id: 3,
    loser: "Loki",
    loser_team: "DOT Robotics",
    winner_team: "BHP Robotics",
    winner: "Sunya Kratos",
    round: "U13",
    event_id: 1,
    event_name: "Gravitas2023",
    video_url: "youtube.com",
    event_array: ["Gravitas", "VIT Vellore", 2023],
    description: "Upper Bracket Round 1",
    knockout: 1,
    category: 15,
  },
  {
    id: 4,
    loser: "Mag",
    loser_team: "Unknown",
    winner_team: "Terrorbull",
    winner: "T-Rex",
    round: "U14",
    event_id: 1,
    event_name: "Gravitas2023",
    video_url: "youtube.com",
    event_array: ["Gravitas", "VIT Vellore", 2023],
    description: "Upper Bracket Round 1",
    knockout: 1,
    category: 15,
  },
  {
    id: 5,
    loser: "Venus",
    loser_team: "VisionBotz",
    winner_team: "Orcus",
    winner: "Vulcan",
    round: "U15",
    event_id: 1,
    event_name: "Gravitas2023",
    video_url: "youtube.com",
    event_array: ["Gravitas", "VIT Vellore", 2023],
    description: "Upper Bracket Round 1",
    knockout: 1,
    category: 15,
  },
  {
    id: 6,
    loser: "Hawk",
    loser_team: "White Shadow",
    winner_team: "VisionBotz",
    winner: "Volt",
    round: "U16",
    event_id: 1,
    event_name: "Gravitas2023",
    video_url: "youtube.com",
    event_array: ["Gravitas", "VIT Vellore", 2023],
    description: "Upper Bracket Round 1",
    knockout: 1,
    category: 15,
  },
  {
    id: 7,
    loser: "Azad",
    loser_team: "Unknown",
    winner_team: "Gladiators",
    winner: "Spartacus",
    round: "U17",
    event_id: 1,
    event_name: "Gravitas2023",
    video_url: "youtube.com",
    event_array: ["Gravitas", "VIT Vellore", 2023],
    description: "Upper Bracket Round 1",
    knockout: 1,
    category: 15,
  },
  {
    id: 8,
    loser_team: "BHP Robotics",
    loser: "Kratos BHP",
    winner_team: "DS Robotics",
    winner: "Rowdy",
    round: "U18",
    event_id: 1,
    event_name: "Gravitas2023",
    video_url: "youtube.com",
    event_array: ["Gravitas", "VIT Vellore", 2023],
    description: "Upper Bracket Round 1",
    knockout: 1,
    category: 15,
  },
  {
    id: 9,
    loser_team: "DOT Robotics",
    loser: "Atherra",
    winner_team: "White Shadow",
    winner: "Mr. Gator",
    round: "L11",
    event_id: 1,
    event_name: "Gravitas2023",
    video_url: "youtube.com",
    event_array: ["Gravitas", "VIT Vellore", 2023],
    description: "Lower Bracket Round 1",
    knockout: 1,
    category: 15,
  },
  {
    id: 10,
    loser_team: "Unknown",
    loser: "Mag",
    winner_team: "DOT Robotics",
    winner: "Loki",
    round: "L12",
    event_id: 1,
    event_name: "Gravitas2023",
    video_url: "youtube.com",
    event_array: ["Gravitas", "VIT Vellore", 2023],
    description: "Lower Bracket Round 1",
    knockout: 1,
    category: 15,
  },
  {
    id: 11,
    loser_team: "VisionBotz",
    loser: "Venus",
    winner_team: "White Shadow",
    winner: "Hawk",
    winner_id: 13,
    round: "L13",
    event_id: 1,
    event_name: "Gravitas2023",
    video_url: "youtube.com",
    event_array: ["Gravitas", "VIT Vellore", 2023],
    description: "Lower Bracket Round 1",
    knockout: 1,
    category: 15,
  },
  {
    id: 12,
    loser_team: "Unknown",
    loser: "Azad",
    winner_team: "BHP Robotics",
    winner: "Kratos BHP",
    round: "L14",
    event_id: 1,
    event_name: "Gravitas2023",
    video_url: "youtube.com",
    event_array: ["Gravitas", "VIT Vellore", 2023],
    description: "Lower Bracket Round 1",
    knockout: 1,
    category: 15,
  },
  {
    id: 13,
    loser_team: "Phoenix Bangalore",
    loser: "Titan Blade",
    winner_team: "Synergy",
    winner: "Synergy",
    round: "U21",
    event_id: 1,
    event_name: "Gravitas2023",
    video_url: "youtube.com",
    event_array: ["Gravitas", "VIT Vellore", 2023],
    description: "Upper Bracket Round 2",
    knockout: 1,
    category: 15,
  },
  {
    id: 14,
    loser_team: "BHP Robotics",
    loser: "Sunya Kratos",
    winner_team: "Terrorbull",
    winner: "T-Rex",
    round: "U22",
    event_id: 1,
    event_name: "Gravitas2023",
    video_url: "youtube.com",
    event_array: ["Gravitas", "VIT Vellore", 2023],
    description: "Upper Bracket Round 2",
    knockout: 1,
    category: 15,
  },
  {
    id: 15,
    loser_team: "Orcus",
    loser: "Vulcan",
    winner_team: "VisionBotz",
    winner: "Volt",
    round: "U23",
    event_id: 1,
    event_name: "Gravitas2023",
    video_url: "youtube.com",
    event_array: ["Gravitas", "VIT Vellore", 2023],
    description: "Upper Bracket Round 2",
    knockout: 1,
    category: 15,
  },
  {
    id: 16,
    loser_team: "DS Robotics",
    loser: "Rowdy",
    winner_team: "Gladiators",
    winner: "Spartacus",
    round: "U24",
    event_id: 1,
    event_name: "Gravitas2023",
    video_url: "youtube.com",
    event_array: ["Gravitas", "VIT Vellore", 2023],
    description: "Upper Bracket Round 2",
    knockout: 1,
    category: 15,
  },
  {
    id: 17,
    loser_team: "BHP Robotics",
    loser: "Sunya Kratos",
    winner_team: "Synergy",
    winner: "Synergy",
    round: "U31",
    event_id: 1,
    event_name: "Gravitas2023",
    video_url: "youtube.com",
    event_array: ["Gravitas", "VIT Vellore", 2023],
    description: "Upper Bracket Round 3",
    knockout: 1,
    category: 15,
  },
  {
    id: 18,
    loser_team: "Gladiators",
    loser: "Spartacus",
    winner_team: "VisionBotz",
    winner: "Volt",
    round: "U32",
    event_id: 1,
    event_name: "Gravitas2023",
    video_url: "youtube.com",
    event_array: ["Gravitas", "VIT Vellore", 2023],
    description: "Upper Bracket Round 3",
    knockout: 1,
    category: 15,
  },
  {
    id: 19,
    loser_team: "VisionBotz",
    loser: "Volt",
    winner_team: "Synergy",
    winner: "Synergy",
    round: "U41",
    event_id: 1,
    event_name: "Gravitas2023",
    video_url: "youtube.com",
    event_array: ["Gravitas", "VIT Vellore", 2023],
    description: "Semi Finals",
    knockout: 1,
    category: 15,
  },
  {
    id: 20,
    loser_team: "VisionBotz",
    loser: "Volt",
    winner_team: "Synergy",
    winner: "Synergy",
    round: "F11",
    event_id: 1,
    event_name: "Gravitas2023",
    video_url: "youtube.com",
    event_array: ["Gravitas", "VIT Vellore", 2023],
    description: "Finals",
    knockout: 1,
    category: 15,
  },
  {
    id: 21,
    loser_team: "DS Robotics",
    loser: "Rowdy",
    winner_team: "White Shadow",
    winner: "Mr. Gator",
    round: "L21",
    event_id: 1,
    event_name: "Gravitas2023",
    video_url: "youtube.com",
    event_array: ["Gravitas", "VIT Vellore", 2023],
    description: "Lower Bracket Round 2",
    knockout: 1,
    category: 15,
  },
  {
    id: 22,
    loser_team: "Orcus",
    loser: "Vulcan",
    winner_team: "DOT Robotics",
    winner: "Loki",
    round: "L22",
    event_id: 1,
    event_name: "Gravitas2023",
    video_url: "youtube.com",
    event_array: ["Gravitas", "VIT Vellore", 2023],
    description: "Lower Bracket Round 2",
    knockout: 1,
    category: 15,
  },
  {
    id: 23,
    loser_team: "White Shadow",
    loser: "Hawk",
    winner_team: "Terrorbull",
    winner: "T-Rex",
    round: "L23",
    event_id: 1,
    event_name: "Gravitas2023",
    video_url: "youtube.com",
    event_array: ["Gravitas", "VIT Vellore", 2023],
    description: "Lower Bracket Round 2",
    knockout: 1,
    category: 15,
  },
  {
    id: 24,
    loser_team: "BHP Robotics",
    loser: "Kratos BHP",
    winner_team: "Phoenix Bangalore",
    winner: "Titan Blade",
    round: "L24",
    event_id: 1,
    event_name: "Gravitas2023",
    video_url: "youtube.com",
    event_array: ["Gravitas", "VIT Vellore", 2023],
    description: "Lower Bracket Round 2",
    knockout: 1,
    category: 15,
  },
  {
    id: 25,
    loser_team: "White Shadow",
    loser: "Mr. Gator",
    winner_team: "DOT Robotics",
    winner: "Loki",
    round: "L31",
    event_id: 1,
    event_name: "Gravitas2023",
    video_url: "youtube.com",
    event_array: ["Gravitas", "VIT Vellore", 2023],
    description: "Lower Bracket Round 3",
    knockout: 1,
    category: 15,
  },
  {
    id: 26,
    loser_team: "Phoenix Bangalore",
    loser: "Titan Blade",
    winner_team: "Terrorbull",
    winner: "T-Rex",
    round: "L32",
    event_id: 1,
    event_name: "Gravitas2023",
    video_url: "youtube.com",
    event_array: ["Gravitas", "VIT Vellore", 2023],
    description: "Lower Bracket Round 3",
    knockout: 1,
    category: 15,
  },
  {
    id: 27,
    loser_team: "BHP Robotics",
    loser: "Sunya Kratos",
    winner_team: "DOT Robotics",
    winner: "Loki",
    winner_id: 16,
    round: "L41",
    event_id: 1,
    event_name: "Gravitas2023",
    video_url: "youtube.com",
    event_array: ["Gravitas", "VIT Vellore", 2023],
    description: "Lower Bracket Round 4",
    knockout: 1,
    category: 15,
  },
  {
    id: 28,
    loser_team: "Gladiators",
    loser: "Spartacus",
    winner_team: "Terrorbull",
    winner: "T-Rex",
    round: "L42",
    event_id: 1,
    event_name: "Gravitas2023",
    video_url: "youtube.com",
    event_array: ["Gravitas", "VIT Vellore", 2023],
    description: "Lower Bracket Round 4",
    knockout: 1,
    category: 15,
  },
  {
    id: 29,
    loser_team: "DOT Robotics",
    loser: "Loki",
    winner_team: "Terrorbull",
    winner: "T-Rex",
    round: "L51",
    event_id: 1,
    event_name: "Gravitas2023",
    video_url: "youtube.com",
    event_array: ["Gravitas", "VIT Vellore", 2023],
    description: "Lower Bracket Round 5",
    knockout: 1,
    category: 15,
  },
  {
    id: 30,
    loser_team: "Terrorbull",
    loser: "T-Rex",
    winner_team: "VisionBotz",
    winner: "Volt",
    round: "L61",
    event_id: 1,
    event_name: "Gravitas2023",
    video_url: "youtube.com",
    event_array: ["Gravitas", "VIT Vellore", 2023],
    description: "3rd Place Match",
    knockout: 1,
    category: 15,
  },
  {
    id: 31,
    loser_team: "Unknown",
    loser: "Gnarly",
    winner_team: "Black Diamond",
    winner: "Hyperion",
    round: "U11",
    event_id: 2,
    event_name: "Engineer2023",
    video_url: "youtube.com",
    event_array: ["Engineer", "NIT Surthkal", 2023],
    description: "Upper Bracket Round 1",
    knockout: 1,
    category: 15,
  },
  {
    id: 32,
    loser_team: "Unknown",
    loser: "Jet",
    winner_team: "Terrorbull",
    winner: "T-Rex",
    round: "U12",
    event_id: 2,
    event_name: "Engineer2023",
    video_url: "youtube.com",
    event_array: ["Engineer", "NIT Surthkal", 2023],
    description: "Upper Bracket Round 1",
    knockout: 1,
    category: 15,
  },
  {
    id: 33,
    loser_team: "TCR",
    loser: "Blood Moon",
    winner_team: "Synergy",
    winner: "Synergy",
    round: "U13",
    event_id: 2,
    event_name: "Engineer2023",
    video_url: "youtube.com",
    event_array: ["Engineer", "NIT Surthkal", 2023],
    description: "Upper Bracket Round 1",
    knockout: 1,
    category: 15,
  },
  {
    id: 34,
    loser_team: "Black Diamond",
    loser: "Hyperion",
    winner_team: "White Shadow",
    winner: "Mr. Gator",
    round: "U21",
    event_id: 2,
    event_name: "Engineer2023",
    video_url: "youtube.com",
    event_array: ["Engineer", "NIT Surthkal", 2023],
    description: "Upper Bracket Round 2",
    knockout: 1,
    category: 15,
  },
  {
    id: 35,
    loser_team: "Synergy",
    loser: "Synergy",
    winner_team: "Terrorbull",
    winner: "T-Rex",
    round: "U22",
    event_id: 2,
    event_name: "Engineer2023",
    video_url: "youtube.com",
    event_array: ["Engineer", "NIT Surthkal", 2023],
    description: "Upper Bracket Round 2",
    knockout: 1,
    category: 15,
  },
  {
    id: 36,
    loser_team: "White Shadow",
    loser: "Mr. Gator",
    winner_team: "Terrorbull",
    winner: "T-Rex",
    round: "U31",
    event_id: 2,
    event_name: "Engineer2023",
    video_url: "youtube.com",
    event_array: ["Engineer", "NIT Surthkal", 2023],
    description: "Upper Bracket Round 3",
    knockout: 1,
    category: 15,
  },
  {
    id: 37,
    loser_team: "Black Diamond",
    loser: "Hyperion",
    winner_team: "Terrorbull",
    winner: "T-Rex",
    round: "F11",
    event_id: 2,
    event_name: "Engineer2023",
    video_url: "youtube.com",
    event_array: ["Engineer", "NIT Surthkal", 2023],
    description: "Finals",
    knockout: 1,
    category: 15,
  },
  {
    id: 38,
    loser_team: "Unknown",
    loser: "Jet",
    winner_team: "TCR",
    winner: "Blood Moon",
    round: "L11",
    event_id: 2,
    event_name: "Engineer2023",
    video_url: "youtube.com",
    event_array: ["Engineer", "NIT Surthkal", 2023],
    description: "Lower Bracket Round 1",
    knockout: 1,
    category: 15,
  },
  {
    id: 39,
    loser_team: "Unknown",
    loser: "Gnarly",
    winner_team: "Synergy",
    winner: "Synergy",
    round: "L21",
    event_id: 2,
    event_name: "Engineer2023",
    video_url: "youtube.com",
    event_array: ["Engineer", "NIT Surthkal", 2023],
    description: "Lower Bracket Round 2",
    knockout: 1,
    category: 15,
  },
  {
    id: 40,
    loser_team: "TCR",
    loser: "Blood Moon",
    winner_team: "Black Diamond",
    winner: "Hyperion",
    round: "L22",
    event_id: 2,
    event_name: "Engineer2023",
    video_url: "youtube.com",
    event_array: ["Engineer", "NIT Surthkal", 2023],
    description: "Lower Bracket Round 2",
    knockout: 1,
    category: 15,
  },
  {
    id: 41,
    loser_team: "Synergy",
    loser: "Synergy",
    winner_team: "Black Diamond",
    winner: "Hyperion",
    round: "L31",
    event_id: 2,
    event_name: "Engineer2023",
    video_url: "youtube.com",
    event_array: ["Engineer", "NIT Surthkal", 2023],
    description: "Lower Bracket Round 3",
    knockout: 1,
    category: 15,
  },
  {
    id: 42,
    loser_team: "White Shadow",
    loser: "Mr. Gator",
    winner_team: "Black Diamond",
    winner: "Hyperion",
    round: "L41",
    event_id: 2,
    event_name: "Engineer2023",
    video_url: "youtube.com",
    event_array: ["Engineer", "NIT Surthkal", 2023],
    description: "Lower Bracket Round 4",
    knockout: 1,
    category: 15,
  },
  {
    id: 43,
    loser_team: "Synergy",
    loser: "Synergy",
    winner_team: "Terrorbull",
    winner: "T-Rex",
    round: "U11",
    event_id: 3,
    event_name: "Techtatva2023",
    video_url: "youtube.com",
    event_array: ["Techtatva", "Manipal", 2023],
    description: "Upper Bracket Round 1",
    knockout: 1,
    category: 15,
  },
  {
    id: 44,
    loser_team: "TCR",
    loser: "Blood Moon",
    winner_team: "Unknown",
    winner: "Gnarly",
    round: "U12",
    event_id: 3,
    event_name: "Techtatva2023",
    video_url: "youtube.com",
    event_array: ["Techtatva", "Manipal", 2023],
    description: "Upper Bracket Round 1",
    knockout: 1,
    category: 15,
  },
  {
    id: 45,
    loser_team: "Unknown",
    loser: "Atom",
    winner_team: "White Shadow",
    winner: "Mr. Gator",
    round: "U13",
    event_id: 3,
    event_name: "Techtatva2023",
    video_url: "youtube.com",
    event_array: ["Techtatva", "Manipal", 2023],
    description: "Upper Bracket Round 1",
    knockout: 1,
    category: 15,
  },
  {
    id: 46,
    loser_team: "Unknown",
    loser: "Skull Crusher",
    winner_team: "White Shadow",
    winner: "Hawk",
    round: "U14",
    event_id: 3,
    event_name: "Techtatva2023",
    video_url: "youtube.com",
    event_array: ["Techtatva", "Manipal", 2023],
    description: "Upper Bracket Round 1",
    knockout: 1,
    category: 15,
  },
  {
    id: 47,
    loser_team: "TCR",
    loser: "Blood Moon",
    winner_team: "Synergy",
    winner: "Synergy",
    round: "L11",
    event_id: 3,
    event_name: "Techtatva2023",
    video_url: "youtube.com",
    event_array: ["Techtatva", "Manipal", 2023],
    description: "Lower Bracket Round 1",
    knockout: 1,
    category: 15,
  },
  {
    id: 48,
    loser_team: "Unknown",
    loser: "Skull Crusher",
    winner_team: "Unknown",
    winner: "Atom",
    round: "U12",
    event_id: 3,
    event_name: "Techtatva2023",
    video_url: "youtube.com",
    event_array: ["Techtatva", "Manipal", 2023],
    description: "Lower Bracket Round 1",
    knockout: 1,
    category: 15,
  },
  {
    id: 49,
    loser_team: "Unknown",
    loser: "Gnarly",
    winner_team: "Terrorbull",
    winner: "T-Rex",
    round: "U21",
    event_id: 3,
    event_name: "Techtatva2023",
    video_url: "youtube.com",
    event_array: ["Techtatva", "Manipal", 2023],
    description: "Upper Bracket Round 2",
    knockout: 1,
    category: 15,
  },
  {
    id: 50,
    loser_team: "White Shadow",
    loser: "Mr. Gator",
    winner_team: "White Shadow",
    winner: "Hawk",
    round: "U22",
    event_id: 3,
    event_name: "Techtatva2023",
    video_url: "youtube.com",
    event_array: ["Techtatva", "Manipal", 2023],
    description: "Upper Bracket Round 2",
    knockout: 1,
    category: 15,
  },
  {
    id: 51,
    loser_team: "White Shadow",
    loser: "Mr. Gator",
    winner_team: "Synergy",
    winner: "Synergy",
    round: "L21",
    event_id: 3,
    event_name: "Techtatva2023",
    video_url: "youtube.com",
    event_array: ["Techtatva", "Manipal", 2023],
    description: "Lower Bracket Round 2",
    knockout: 1,
    category: 15,
  },
  {
    id: 52,
    loser_team: "Unknown",
    loser: "Atom",
    winner_team: "Unknown",
    winner: "Gnarly",
    round: "L22",
    event_id: 3,
    event_name: "Techtatva2023",
    video_url: "youtube.com",
    event_array: ["Techtatva", "Manipal", 2023],
    description: "Lower Bracket Round 2",
    knockout: 1,
    category: 15,
  },
  {
    id: 53,
    loser_team: "White Shadow",
    loser: "Hawk",
    winner_team: "Terrorbull",
    winner: "T-Rex",
    round: "U31",
    event_id: 3,
    event_name: "Techtatva2023",
    video_url: "youtube.com",
    event_array: ["Techtatva", "Manipal", 2023],
    description: "Upper Bracket Round 3",
    knockout: 1,
    category: 15,
  },
  {
    id: 54,
    loser_team: "Synergy",
    loser: "Synergy",
    winner_team: "Unknown",
    winner: "Gnarly",
    round: "L31",
    event_id: 3,
    event_name: "Techtatva2023",
    video_url: "youtube.com",
    event_array: ["Techtatva", "Manipal", 2023],
    description: "Lower Bracket Round 3",
    knockout: 1,
    category: 15,
  },
  {
    id: 55,
    loser_team: "White Shadow",
    loser: "Hawk",
    winner_team: "Unknown",
    winner: "Gnarly",
    round: "L41",
    event_id: 3,
    event_name: "Techtatva2023",
    video_url: "youtube.com",
    event_array: ["Techtatva", "Manipal", 2023],
    description: "Lower Bracket Round 4",
    knockout: 1,
    category: 15,
  },
  {
    id: 56,
    loser_team: "Unknown",
    loser: "Gnarly",
    winner_team: "Terrorbull",
    winner: "T-Rex",
    round: "F11",
    event_id: 3,
    event_name: "Techtatva2023",
    video_url: "youtube.com",
    event_array: ["Techtatva", "Manipal", 2023],
    description: "Finals",
    knockout: 1,
    category: 15,
  },
];

export default matches_list;
