const robots_list = [
  {
    name: "Rowdy",
    weight: 15,
    team_name: "DS Robotics",
  },
  {
    name: "Durvasa",
    weight: 15,
    team_name: "Unicorn",
  },
  {
    name: "Fury",
    weight: 60,
    team_name: "Team Fury",
  },
  {
    name: "Karna",
    weight: 30,
    team_name: "Unicorn",
  },
  {
    name: "Hyperion",
    weight: 15,
    team_name: "Black Diamond",
  },
  {
    name: "Trishul",
    weight: 15,
    team_name: "StormBotz",
  },
  {
    name: "Raptor",
    weight: 8,
    team_name: "Terrorbull",
  },
  {
    name: "T-Rex",
    weight: 15,
    team_name: "Terrorbull",
  },
  {
    name: "Morarji",
    weight: 15,
    team_name: "Knockbotz",
  },
  {
    name: "Tauras",
    weight: 30,
    team_name: "Terrorbull",
  },
  {
    name: "Thapar",
    weight: 60,
    team_name: "Knockbotz",
  },
  {
    name: "Terminator",
    weight: 60,
    team_name: "Terrorbull",
  },
  {
    name: "Prixx",
    weight: 60,
    team_name: "Prixx Robotics",
  },
  {
    name: "Sarkhel",
    weight: 110,
    team_name: "Prixx Robotics",
  },
  {
    name: "Synergy",
    weight: 15,
    team_name: "Synergy",
  },
  {
    name: "Loki",
    weight: 15,
    team_name: "DOT Robotics",
  },
  {
    name: "Theera",
    weight: 60,
    team_name: "DOT Robotics",
  },
  {
    name: "Parshu",
    weight: 15,
    team_name: "Dark Technocruz",
  },
  {
    name: "Daddy",
    weight: 8,
    team_name: "Eastern Botz",
  },
  {
    name: "Hunterrr",
    weight: 15,
    team_name: "Eastern Botz",
  },
  {
    name: "Mr. Gator",
    weight: 15,
    team_name: "White Shadow",
  },
  {
    name: "Hawk",
    weight: 15,
    team_name: "White Shadow",
  },
  {
    name: "Blood Moon",
    weight: 15,
    team_name: "TCR",
  },
  {
    name: "Agni",
    weight: 15,
    team_name: "Stormbotz",
  },
  {
    name: "Spartacus",
    weight: 15,
    team_name: "Gladiators",
  },
  {
    name: "Tandav",
    weight: 15,
    team_name: "Godfather",
  },
  {
    name: "Destro",
    weight: 15,
    team_name: "Rock Robotics",
  },
  {
    name: "Rock Redefined",
    weight: 60,
    team_name: "Rock Robotics",
  },
  {
    name: "Zenith",
    weight: 15,
    team_name: "Decepticons",
  },
  {
    name: "Sagar",
    weight: 15,
    team_name: "Decepticons",
  },
];

export default robots_list;
