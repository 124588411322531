import * as React from "react";
import { Link } from "react-router-dom";

import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import { Container } from "@mui/material";
import Box from "@mui/material/Box/Box";
import IconButton from "@mui/material/IconButton";
import HandymanIcon from "@mui/icons-material/Handyman";

class ImageGrid extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Container maxWidth="xl">
          <Box
            maxWidth="xl"
            sx={{
              display: { xs: "none", md: "flex" },
              justifyContent: "center",
            }}
          >
            <ImageList sx={{ padding: 2 }} cols={4} rowHeight={300}>
              {this.props.imageList.map((item) => (
                <Link
                  to={`/Robots/${item.name}`}
                  style={{ textDecoration: "none" }}
                >
                  <ImageListItem key={item.img}>
                    <img
                      src={`${
                        process.env.REACT_APP_CLOUDINARY_BUCKET +
                        "w_500/q_auto/f_auto/" +
                        item.robot_img
                      }`}
                      alt={item.name}
                      loading="lazy"
                      className="ImageSpecs"
                      width="auto"
                      height={248}
                      fit="crop"
                    />
                    <ImageListItemBar
                      title={`${item.name} | ${item.weight}kg`}
                      subtitle={item.team_name}
                      actionIcon={
                        <IconButton
                          sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                          aria-label={`info about ${item.name}`}
                        >
                          <HandymanIcon />
                        </IconButton>
                      }
                      actionPosition="left"
                      position="below"
                    />
                  </ImageListItem>
                </Link>
              ))}
            </ImageList>
          </Box>

          <Box
            maxWidth="xl"
            sx={{
              display: { xs: "flex", md: "none" },
              justifyContent: "center",
            }}
          >
            <ImageList sx={{ padding: 2 }} cols={1} rowHeight={270}>
              {this.props.imageList.map((item) => (
                <Link
                  to={`/Robots/${item.name}`}
                  style={{ textDecoration: "none" }}
                >
                  <ImageListItem key={item.name}>
                    <img
                      src={`${
                        process.env.REACT_APP_CLOUDINARY_BUCKET +
                        "w_500/q_auto/f_auto/" +
                        item.robot_img
                      }`}
                      alt={item.name}
                      loading="lazy"
                      className="ImageSpecs"
                      height={248}
                      width="auto"
                      fit="crop"
                    />
                    <ImageListItemBar
                      title={`${item.name} | ${item.weight}kg`}
                      subtitle={item.team_name}
                      actionIcon={
                        <IconButton
                          sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                          aria-label={`info about ${item.name}`}
                        >
                          <HandymanIcon />
                        </IconButton>
                      }
                      actionPosition="left"
                      position="below"
                    />
                  </ImageListItem>
                </Link>
              ))}
            </ImageList>
          </Box>
        </Container>
      </React.Fragment>
    );
  }
}

export default ImageGrid;
