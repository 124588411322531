import React from "react";
import Helmet from "react-helmet";
import { useEffect, useState } from "react";
import "../styles.css";
import "./home_view.css";
import { db } from "../firebase";

import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

//Slideshow Parameters
const delay = 5000;
const img_links = [
  "v1710504742/robowars/highlights/ybekuyj0egfpvng3ijiw.jpg",
  "v1710504753/robowars/highlights/ycft5fmwxrmexqcoe00h.jpg",
  "v1710504745/robowars/highlights/ni2ufplkb093iqfqzgfu.jpg",
  "v1710516628/robowars/highlights/i2mosxi1kbufg7t1ehsq.jpg",
];

function HomeView() {
  const [index, setIndex] = React.useState(0);
  const [blogs, setBlogs] = useState([]);
  const [loaded, setLoaded] = useState();
  const timeoutRef = React.useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  const fetchBlogs = async () => {
    console.log("fgagoa");
    const response = db.collection("Homepage").doc("defaults");
    const data = await response.get();
    setBlogs((blogs) => data.data());
    setLoaded((loaded) => 1);
    console.log(blogs);
  };

  React.useEffect(() => {
    if (Object.keys(blogs).length === 0) {
      fetchBlogs();
    }
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === img_links.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [index, blogs]);
  if (loaded) {
    return (
      <React.Fragment>
        {/* <Helmet>
        <link
          rel="canonical"
          href={`${"https://robowars.co.in/Events/" + blogs[0].name}`}
        />
        <title>
          {`${blogs[0].name + " " + blogs[0].year + " | Robowars India"}`}
        </title>
        <meta
          name="description"
          content={`${
            "Robowars event held at " +
            blogs[0].name +
            " , " +
            blogs[0].location +
            " on " +
            blogs[0].startDate
              .toDate()
              .toDateString()
              .split(" ")
              .slice(1)
              .join(" ")
          }`}
        />
        <meta
          name="keywords"
          content={`${
            "Combat Robotics, Robowars, Robots, Events, Team, Combat Robots, Robowars Robots, Robowars India, Robotics, Robotics India, Live, Techfest, Techinical Fest, Robotics Community, Robotics India, Robotic, Robots, Battlebots, Featherweight, Heavyweight, Lightweight, Hobbyweight, Bettleweight, Events, " +
            blogs[0].name +
            ", " +
            blogs[0].location
          }`}
        />
        <meta
          property="og:title"
          content={`${
            blogs[0].name + " " + blogs[0].year + " | Robowars India"
          }`}
        />
        <meta
          property="og:url"
          content={`${"https://robowars.co.in/Events/" + blogs[0].name}`}
        />
        <meta property="og:site_name" content="Robowars India" />
        <meta property="og:type" content="website" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content={`${
            blogs[0].name + " " + blogs[0].year + " | Robowars India"
          }`}
        />
        <meta
          name="twitter:description"
          content={`${
            "Robowars event held at " +
            blogs[0].name +
            " , " +
            blogs[0].location +
            " on " +
            blogs[0].startDate
              .toDate()
              .toDateString()
              .split(" ")
              .slice(1)
              .join(" ")
          }`}
        />
      </Helmet> */}

        {/* Desktop Varient Only */}
        <Grid
          container
          alignItems="center"
          sx={{
            display: { xs: "none", md: "flex" },
            margin: 0,
            backgroundColor: "black",
          }}
        >
          <Grid item xs={12} disableGutters>
            <div class="slidecontainer">
              <div className="slideshow">
                <div
                  className="slideshowSlider"
                  style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
                >
                  {blogs.topImages.map((photo, index) => (
                    <div className="slide" key={index}>
                      <img
                        src={`${
                          process.env.REACT_APP_CLOUDINARY_BUCKET + photo
                        }`}
                        alt={`${photo}`}
                        loading="lazy"
                        fit="crop"
                        width="100%"
                        height="600px"
                        className="ImageSpecs"
                      />
                    </div>
                  ))}
                </div>
                <div className="slideshowDots">
                  {img_links.map((_, idx) => (
                    <div
                      key={idx}
                      className={`slideshowDot${
                        index === idx ? " active" : ""
                      }`}
                      onClick={() => {
                        setIndex(idx);
                      }}
                    ></div>
                  ))}
                </div>
              </div>
            </div>
          </Grid>

          {/* Upcoming Section */}

          <Grid
            item
            xs={12}
            sx={{
              height: "300px",
              width: "100%",
              textAlign: "center",
              margin: 0,
            }}
          >
            <Grid
              container
              alignItems="center"
              sx={{
                margin: 0,
                height: "300px",
              }}
            >
              <Grid item xs={6} disableGutters>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    margin: 0,
                    height: "150px",
                    width: "70%",
                  }}
                >
                  <Grid
                    item
                    sx={{
                      margin: 0,
                      height: "100%",
                      width: "4%",
                      backgroundColor: "#ff5100",
                    }}
                  ></Grid>
                  <Grid item>
                    <Typography
                      variant="h2"
                      textAlign="center"
                      sx={{ height: "80%" }}
                      marginLeft="10%"
                      color="white"
                    >
                      Upcoming
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} disableGutters>
                <Grid item>
                  <Typography
                    variant="h4"
                    textAlign="center"
                    sx={{ height: "80%" }}
                    marginLeft="10px"
                    color="white"
                  >
                    To be announced
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* Meet the teams/robots */}

          <Grid
            item
            xs={12}
            sx={{
              height: "600px",
              width: "100%",
              textAlign: "center",
              margin: 0,
              backgroundColor: "white",
            }}
          >
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              sx={{
                margin: 0,
                height: "100%",
              }}
            >
              <Grid item xs={8}>
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="stretch"
                  sx={{
                    paddingLeft: "50px",
                  }}
                  disableGutters
                >
                  <Grid
                    item
                    xs={12}
                    backgroundColor="white"
                    sx={{ width: "100%" }}
                  >
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      sx={{
                        margin: 0,
                      }}
                      disableGutters
                    >
                      <Grid
                        item
                        sx={{
                          margin: 0,
                          height: "50px",
                          width: "2%",
                          backgroundColor: "#ff5100",
                        }}
                      />
                      <Grid item>
                        <Typography
                          variant="h4"
                          textAlign="center"
                          sx={{ height: "80%", width: "100%" }}
                          marginLeft="5%"
                          color="black"
                        >
                          Meet {blogs.meetTeam[0]}
                        </Typography>
                      </Grid>
                    </Grid>
                    {/* Team Description */}
                    <Grid item xs={12} marginTop="10px">
                      <Typography
                        variant="subtitle1"
                        textAlign="justify"
                        sx={{ height: "80%" }}
                        margin="0"
                        width="70%"
                        color="black"
                      >
                        {blogs.meetTeam[1]}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4} disableGutters>
                <Grid item>
                  <img
                    src={
                      process.env.REACT_APP_CLOUDINARY_BUCKET +
                      blogs.meetTeam[2]
                    }
                    alt={blogs.meetTeam[0]}
                    loading="lazy"
                    className="ImageSingleRobotInfo"
                  />
                </Grid>
              </Grid>

              {/* Meet the bots */}
              <Grid item xs={4} disableGutters>
                <Grid item>
                  <img
                    src={
                      process.env.REACT_APP_CLOUDINARY_BUCKET + blogs.meetBot[2]
                    }
                    alt={blogs.meetBot[0]}
                    loading="lazy"
                    className="ImageSingleRobotInfo"
                  />
                </Grid>
              </Grid>
              <Grid item xs={8}>
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="stretch"
                  sx={{
                    paddingLeft: "50px",
                  }}
                  disableGutters
                >
                  <Grid
                    item
                    xs={12}
                    backgroundColor="white"
                    sx={{ width: "100%" }}
                  >
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      sx={{
                        margin: 0,
                      }}
                      disableGutters
                    >
                      <Grid
                        item
                        sx={{
                          margin: 0,
                          height: "50px",
                          width: "2%",
                          backgroundColor: "#ff5100",
                        }}
                      />
                      <Grid item>
                        <Typography
                          variant="h4"
                          textAlign="center"
                          sx={{ height: "80%", width: "100%" }}
                          marginLeft="5%"
                          color="black"
                        >
                          Meet {blogs.meetBot[0]}
                        </Typography>
                      </Grid>
                    </Grid>
                    {/* Bot Description */}
                    <Grid item xs={12} marginTop="10px">
                      <Typography
                        variant="subtitle1"
                        textAlign="justify"
                        sx={{ height: "80%" }}
                        margin="0"
                        width="70%"
                        color="black"
                      >
                        Meet {blogs.meetBot[1]}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* For next section */}
          <Grid
            item
            xs={12}
            sx={{
              height: "300px",
              width: "100%",
              textAlign: "center",
              margin: 0,
            }}
          ></Grid>
        </Grid>

        {/* Mobile Varient Only */}
        <Grid
          container
          alignItems="center"
          sx={{
            display: { xs: "flex", md: "none" },
            margin: 0,
            backgroundColor: "black",
          }}
        >
          <Grid item xs={12}>
            <div class="slidecontainer">
              <div className="slideshow">
                <div
                  className="slideshowSlider"
                  style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
                >
                  {blogs.topImages.map((photo, index) => (
                    <div className="slide" key={index}>
                      <img
                        src={`${
                          process.env.REACT_APP_CLOUDINARY_BUCKET + photo
                        }`}
                        alt={`${photo}`}
                        loading="lazy"
                        fit="crop"
                        width="100%"
                        height="200px"
                        className="ImageSpecs"
                      />
                    </div>
                  ))}
                </div>

                <div className="slideshowDots">
                  {img_links.map((_, idx) => (
                    <div
                      key={idx}
                      className={`slideshowDot${
                        index === idx ? " active" : ""
                      }`}
                      onClick={() => {
                        setIndex(idx);
                      }}
                    ></div>
                  ))}
                </div>
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              height: "300px",
              width: "100%",
              textAlign: "center",
              margin: 0,
            }}
          ></Grid>
          <Grid item xs={12} disableGutters></Grid>
        </Grid>
      </React.Fragment>
    );
  } else {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={!loaded}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
}

export default HomeView;
