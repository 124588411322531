import React from "react";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";
import Pagination from "@mui/material/Pagination";

import LocationOnIcon from "@mui/icons-material/LocationOn";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

const PER_PAGE = 4;

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

class ViewTableComponent extends React.Component {
  //Consturctor for Initalization
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      maxpage: 1,
      count: 1,
    };
    this.handleChange = this.handleChange.bind(this);
    this.setPage = this.setPage.bind(this);
    this.jump = this.jump.bind(this);
  }

  //This function is called after constructor. For more details, read
  //react components lifecycle

  static getDerivedStateFromProps(props, state) {
    return {
      maxPage: Math.ceil(props.DataObj.length / PER_PAGE),
      count: Math.ceil(props.DataObj.length / PER_PAGE),
    };
  }

  setPage(p) {
    this.setState({ page: p });
  }

  setMaxPage() {
    this.setState({ maxPage: Math.ceil(this.props.DataObj.length / PER_PAGE) });
  }
  currentData() {
    const begin = (this.state.page - 1) * PER_PAGE;
    const end = begin + PER_PAGE;
    return this.props.DataObj.slice(begin, end);
  }

  next() {
    this.setMaxPage();
    this.setState({ page: Math.min(this.state.page + 1, this.state.maxPage) });
  }

  prev() {
    this.setState({ page: Math.max(this.state.page - 1, 1) });
  }

  jump(p) {
    this.setMaxPage();
    const pageNumber = Math.max(1, p);
    this.setState({ page: Math.min(pageNumber, this.state.maxPage) });
  }

  handleChange(e, p) {
    this.setState({ page: p });
    this.jump(p);
  }

  render() {
    return (
      <React.Fragment>
        <Grid
          container
          alignItems="center"
          sx={{ display: { xs: "none", md: "flex" } }}
        >
          <Grid item xs={12}>
            <>
              {this.currentData().map((blog) => {
                return (
                  <Grid
                    container
                    direction="column"
                    justifyContent="space-evenly"
                    alignItems="center"
                    sx={{ padding: "1.5%" }}
                  >
                    <Link
                      to={`/Event/${blog.name}`}
                      style={{ textDecoration: "none" }}
                    >
                      <Grid item>
                        <Paper
                          sx={{
                            padding: 2,
                            margin: "auto",
                            maxWidth: 900,
                            flexGrow: 1,
                            backgroundColor: (theme) =>
                              theme.palette.mode === "dark"
                                ? "#1A2027"
                                : "#fff",
                          }}
                        >
                          <Grid container>
                            <Grid item>
                              <ButtonBase sx={{ width: 175, height: 175 }}>
                                <Img
                                  src={
                                    process.env.REACT_APP_CLOUDINARY_BUCKET +
                                    blog.event_src
                                  }
                                  alt="Shaka"
                                />
                              </ButtonBase>
                            </Grid>
                            <Grid item sx={{ width: "10px" }} />
                            <Grid
                              item
                              xs={12}
                              sm
                              container
                              sx={{ border: 1, borderColor: "#ff0000" }}
                            >
                              <Grid
                                item
                                xs={6}
                                wrap="nowrap"
                                direction="row"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                sx={{ border: 1, borderColor: "#ff0000" }}
                              >
                                <Typography varient="h1" fontSize={24}>
                                  {blog.name}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                wrap="nowrap"
                                direction="row"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                sx={{ border: 1, borderColor: "#ff0000" }}
                              >
                                <Typography varient="h1" fontSize={20}>
                                  <LocationOnIcon
                                    style={{ verticalAlign: "text-top" }}
                                  />
                                  {blog.location}
                                </Typography>
                              </Grid>

                              <Grid
                                item
                                xs={12}
                                alignItems="center"
                                justifyContent="center"
                                display="flex"
                                sx={{ border: 1, borderColor: "#ff0000" }}
                              >
                                <Typography varient="subtitle1" fontSize="1rem">
                                  <CalendarMonthIcon
                                    style={{ verticalAlign: "top" }}
                                  />{" "}
                                  {blog.startDate
                                    .toDate()
                                    .toDateString()
                                    .split(" ")
                                    .slice(1)
                                    .join(" ")}{" "}
                                  -{" "}
                                  {blog.endDate
                                    .toDate()
                                    .toDateString()
                                    .split(" ")
                                    .slice(1)
                                    .join(" ")}
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Grid
                                  container
                                  direction="row"
                                  sx={{ height: "100%" }}
                                >
                                  {blog.weight.map((element) => {
                                    var block_size = 12 / blog.weight.length;
                                    return (
                                      <Grid
                                        item
                                        xs={block_size}
                                        wrap="nowrap"
                                        direction="row"
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        sx={{
                                          border: 1,
                                          borderColor: "#ff0000",
                                        }}
                                      >
                                        <Typography varient="h1" fontSize={18}>
                                          {element}
                                        </Typography>
                                      </Grid>
                                    );
                                  })}
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                wrap="nowrap"
                                direction="row"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                sx={{ border: 1, borderColor: "#ff0000" }}
                                backgroundColor={
                                  blog.prizePaid === false
                                    ? "#fa8072"
                                    : "#b0fc38"
                                }
                              >
                                <Typography varient="h1" fontSize={18}>
                                  {blog.startDate["seconds"] * 1000 > Date.now()
                                    ? "Prize Money : " + blog.prize
                                    : "Prize : " +
                                      (blog.prizePaid === true
                                        ? "Paid in full"
                                        : "Not yet. Read Notes")}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                    </Link>
                  </Grid>
                );
              })}
            </>
          </Grid>
          <Grid
            marginTop={2}
            marginBottom={2}
            item
            xs={12}
            sx={{
              alignItems: "center",
              display: "flex",
            }}
            justifyContent="center"
          >
            <Pagination
              count={this.state.count}
              size="large"
              page={this.state.page}
              variant="outlined"
              shape="rounded"
              onChange={this.handleChange}
              sx={{ display: "flex", justifyContent: "center" }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          marginTop={2}
          sx={{ display: { xs: "flex", md: "none" } }}
        >
          <Grid item xs={12}>
            <>
              {this.currentData().map((blog) => {
                return (
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="center"
                    display="flex"
                    marginTop={2}
                  >
                    <Link
                      to={`/Event/${blog.name}`}
                      style={{ textDecoration: "none" }}
                    >
                      <Grid item sx={{ border: 1, borderColor: "black" }}>
                        <Paper
                          sx={{
                            padding: 2,
                            margin: "auto",
                            maxWidth: 900,
                            flexGrow: 1,
                            border: 1,
                            borderColor: "red",
                            backgroundColor: (theme) =>
                              theme.palette.mode === "dark"
                                ? "#1A2027"
                                : "#fff",
                          }}
                        >
                          <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                            display="flex"
                          >
                            <Grid
                              item
                              xs={12}
                              paddingLeft={0}
                              paddingTop={0}
                              alignItems="center"
                              sx={{
                                border: 1,
                                borderColor: "blue",
                              }}
                              justifyContent="center"
                              display="flex"
                            >
                              <ButtonBase sx={{ width: 200, height: 200 }}>
                                <Img
                                  src={
                                    process.env.REACT_APP_CLOUDINARY_BUCKET +
                                    blog.event_src
                                  }
                                  alt="Shaka"
                                />
                              </ButtonBase>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm
                              container
                              sx={{ border: 1, borderColor: "#ff0000" }}
                            >
                              <Grid
                                item
                                xs={12}
                                wrap="nowrap"
                                direction="row"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                sx={{ border: 1, borderColor: "#ff0000" }}
                              >
                                <Typography varient="h1" fontSize={24}>
                                  {blog.name}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                wrap="nowrap"
                                direction="row"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                sx={{ border: 1, borderColor: "#ff0000" }}
                              >
                                <Typography varient="h1" fontSize={20}>
                                  <LocationOnIcon
                                    style={{ verticalAlign: "text-top" }}
                                  />
                                  {blog.location}
                                </Typography>
                              </Grid>

                              <Grid
                                item
                                xs={12}
                                alignItems="center"
                                justifyContent="center"
                                display="flex"
                                sx={{ border: 1, borderColor: "#ff0000" }}
                              >
                                <Typography varient="subtitle1" fontSize="1rem">
                                  <CalendarMonthIcon
                                    style={{ verticalAlign: "top" }}
                                  />{" "}
                                  {blog.startDate
                                    .toDate()
                                    .toDateString()
                                    .split(" ")
                                    .slice(1)
                                    .join(" ")}{" "}
                                  -{" "}
                                  {blog.endDate
                                    .toDate()
                                    .toDateString()
                                    .split(" ")
                                    .slice(1)
                                    .join(" ")}
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Grid container direction="row">
                                  {blog.weight.map((element) => {
                                    var block_size = 12 / blog.weight.length;
                                    return (
                                      <Grid
                                        item
                                        xs={block_size}
                                        alignItems="center"
                                        justifyContent="center"
                                        display="flex"
                                        textAlign="center"
                                      >
                                        <Typography
                                          varient="h2"
                                          fontSize={20}
                                          sx={{
                                            border: 1,
                                            borderColor: "#ff0000",
                                            width: "100%",
                                          }}
                                        >
                                          {element}
                                        </Typography>
                                      </Grid>
                                    );
                                  })}
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                alignItems="center"
                                display="flex"
                                justifyContent="center"
                              >
                                <Typography
                                  varient="body1"
                                  fontsize="0.5rem"
                                  alignItems="center"
                                  verticalAlign="center"
                                  sx={{
                                    border: 1,
                                    borderColor: "#ff0000",
                                    width: "100%",
                                    textAlign: "center",
                                    height: "100%",
                                  }}
                                  backgroundColor={
                                    blog.prizePaid === false
                                      ? "#fa8072"
                                      : "#b0fc38"
                                  }
                                >
                                  {blog.startDate["seconds"] * 1000 > Date.now()
                                    ? "Prize : " + blog.prize
                                    : blog.prizePaid === true
                                    ? "Prize : Paid in full"
                                    : "Prize : Not yet. Read notes"}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                    </Link>
                  </Grid>
                );
              })}
            </>
          </Grid>
          <Grid
            item
            xs={12}
            marginTop={2}
            marginBottom={2}
            sx={{
              alignItems: "center",
              display: "flex",
            }}
            justifyContent="center"
          >
            <Pagination
              count={this.state.count}
              size="large"
              page={this.state.page}
              variant="outlined"
              shape="rounded"
              onChange={this.handleChange}
              sx={{ display: "flex", justifyContent: "center" }}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default ViewTableComponent;
