import * as React from "react";
import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import IconButton from "@mui/material/IconButton";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box/Box";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import "./robot.css";
import { Container } from "@mui/material";

import { db } from "../firebase";

export default function Teams() {
  const [teams, setTeams] = useState([]);
  const [loaded, setLoaded] = useState();

  const fetchTeams = async () => {
    const response = db.collection("Teams");
    const data = await response.get();
    data.docs.forEach((item) => {
      setTeams((teams) => [...teams, item.data()]);
    });
    setLoaded((loaded) => 1);
  };

  //Use Effect function for async
  useEffect(() => {
    fetchTeams();
  }, []);
  if (loaded) {
    return (
      <React.Fragment>
        <Helmet>
          <link rel="canonical" href="https://robowars.co.in/Teams" />
          <title>Teams | Robowars India</title>
          <meta
            name="description"
            content="All Teams participating in Combat Robotics or Robowars Event in India"
          />
          <meta property="og:title" content="Teams | Robowars India" />
          <meta property="og:url" content="https://robowars.co.in/Teams" />
          <meta property="og:site_name" content="Robowars India" />
          <meta property="og:type" content="website" />
          <meta
            name="keywords"
            content="Combat Robotics, Robowars, Teams, Robots, Combat Robots, Robowars Robots, Robowars India, Robotics, Robotics India, Live, Techfest, Techinical Fest, Robotics Community, Robotics India, Robotic, Robots, Battlebots, Featherweight, Heavyweight, Lightweight, Hobbyweight, Bettleweight, Events"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Teams | Robowars India" />
          <meta
            name="twitter:description"
            content="All Teams participating in Combat Robotics or Robowars Event in India"
          />
        </Helmet>
        <Container maxWidth="xl">
          <Box maxWidth="xl" sx={{ display: { xs: "none", md: "flex" } }}>
            <ImageList sx={{ padding: 2 }} cols={4} rowHeight={400}>
              {teams.map((item) => (
                <Link
                  to={`/Teams/${item.team_name}`}
                  style={{ textDecoration: "none" }}
                >
                  <ImageListItem key={item.team_logo}>
                    <img
                      src={`${
                        process.env.REACT_APP_CLOUDINARY_BUCKET +
                        "w_500/q_auto/f_auto/" +
                        item.team_logo
                      }`}
                      alt={item.team_name}
                      loading="lazy"
                      className="ImageSpecs"
                      width="auto"
                      height={248}
                      object-fit="scale-down"
                    />
                    <ImageListItemBar
                      title={item.team_name}
                      actionIcon={
                        <IconButton
                          sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                          aria-label={`info about ${item.name}`}
                        >
                          <WorkspacesIcon />
                        </IconButton>
                      }
                      actionPosition="left"
                      position="below"
                    />
                  </ImageListItem>
                </Link>
              ))}
            </ImageList>
          </Box>
          <Box maxWidth="xl" sx={{ display: { xs: "flex", md: "none" } }}>
            <ImageList sx={{ padding: 1 }} cols={2} rowHeight={210}>
              {teams.map((item) => (
                <Link
                  to={`/Teams/${item.team_name}`}
                  style={{ textDecoration: "none" }}
                >
                  <ImageListItem
                    key={item.team_logo}
                    sx={{ "& .MuiImageListItem-img": { objectFit: "contain" } }}
                  >
                    <img
                      src={`${
                        process.env.REACT_APP_CLOUDINARY_BUCKET +
                        "w_500/q_auto/f_auto/" +
                        item.team_logo
                      }`}
                      alt={item.team_name}
                      loading="lazy"
                      className="ImageSpecs"
                      height={250}
                      width="auto"
                      object-fit="scale-down"
                    />
                    <ImageListItemBar
                      title={item.team_name}
                      actionIcon={
                        <IconButton
                          sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                          aria-label={`info about ${item.name}`}
                        >
                          <WorkspacesIcon />
                        </IconButton>
                      }
                      actionPosition="left"
                      position="below"
                    />
                  </ImageListItem>
                </Link>
              ))}
            </ImageList>
          </Box>
        </Container>
      </React.Fragment>
    );
  } else {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={!loaded}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
}
