import React from "react";
import { useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import "./robotDetails.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useState, useEffect } from "react";

import { db } from "../firebase";
import { Typography } from "@mui/material";
import { Helmet } from "react-helmet";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  flexDirection: "column",
  margin: "8px",
  border: "1px solid red",
}));

const RoboDetails = () => {
  let { id } = useParams();
  const [bot_info, setBotInfo] = useState();
  const [loaded, setLoaded] = useState(0);
  const query1 = db.collection("Robots").where(`name`, `==`, id);
  const fetchBlogs = async () => {
    const querySnapshot = await query1.get();
    querySnapshot.forEach((item) => {
      setBotInfo((bot_info) => item.data());
    });
    setLoaded((loaded) => 1);
  };
  useEffect(() => {
    fetchBlogs();
  }, 0);
  if (loaded) {
    return (
      <React.Fragment>
        <Helmet>
          <link
            rel="canonical"
            href={`${"https://robowars.co.in/Robots/" + bot_info["name"]}`}
          />
          <title>{bot_info["name"]} | Robowars India</title>
          <meta
            name="description"
            content={`${
              bot_info["name"] +
              " is a " +
              bot_info["weight"] +
              "kg from " +
              bot_info["team_name"]
            }`}
          />
          <meta
            name="keywords"
            content={`${
              "Combat Robotics, Robowars, Robots, Combat Robots, Robowars Robots, Robowars India, Robotics, Robotics India, Live, Techfest, Techinical Fest, Robotics Community, Robotics India, Robotic, Robots, Battlebots, Featherweight, Heavyweight, Lightweight, Hobbyweight, Bettleweight, Events, " +
              bot_info["name"] +
              ", " +
              bot_info["team_name"]
            }`}
          />
          <meta
            property="og:title"
            content={`${bot_info["name"] + " | Robowars India"}`}
          />
          <meta
            property="og:url"
            content={`${"https://robowars.co.in/Robots/" + bot_info["name"]}`}
          />
          <meta property="og:site_name" content="Robowars India" />
          <meta property="og:type" content="website" />

          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content={`${bot_info["name"] + " | Robowars India"}`}
          />
          <meta
            name="twitter:description"
            content={`${
              bot_info["name"] +
              " is a " +
              bot_info["weight"] +
              "kg from " +
              bot_info["team_name"]
            }`}
          />
        </Helmet>
        <Grid
          container
          marginTop={2}
          alignItems="center"
          sx={{ display: { xs: "none", md: "flex" } }}
        >
          <Grid item xs={7}>
            <Item elevation={16} sx={{ height: 600, width: "auto" }}>
              <img
                src={`${
                  process.env.REACT_APP_CLOUDINARY_BUCKET +
                  "w_1000/q_70/f_auto/" +
                  bot_info["robot_img"]
                }`}
                alt={`${bot_info["name"]}`}
                loading="lazy"
                className="ImageSingleRobotInfo"
                object-fit="contain"
              />
            </Item>
          </Grid>
          <Grid item xs={5} display="flex" flexDirection="column">
            <Grid
              container
              marginTop={2}
              alignItems="stretch"
              direction="column"
              display="flex"
              sx={{ border: 1, borderColor: "#ff0000" }}
            >
              <Grid item xs={12} sx={{ border: 1, borderColor: "#ff0000" }}>
                <Typography
                  varient="h1"
                  noWrap
                  fontSize={100}
                  fontWeight={3}
                  textAlign="center"
                  sx={{ border: 1, borderColor: "#0000ff" }}
                >
                  {bot_info["name"]}
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ border: 1, borderColor: "#ff0000" }}>
                <Link
                  to={`/Teams/${bot_info["team_name"]}`}
                  style={{ textDecoration: "none" }}
                >
                  <Typography
                    varient="h1"
                    fontSize={80}
                    fontWeight={2}
                    textAlign="center"
                    sx={{ border: 1, borderColor: "#0000ff" }}
                  >
                    {bot_info["team_name"]}
                  </Typography>
                </Link>
              </Grid>
              <Grid item xs={12} sx={{ border: 1, borderColor: "#ff0000" }}>
                <Accordion
                  disabled
                  expanded="false"
                  sx={{ justifyContent: "center" }}
                >
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    display="flex"
                    sx={{
                      justifyContent: "center",
                      "& .MuiAccordionSummary-content": {
                        justifyContent: "center",
                      },
                    }}
                  >
                    <Typography variant="h4" textAlign="center">
                      {bot_info["weight"]} kg
                    </Typography>
                  </AccordionSummary>
                </Accordion>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          marginTop={2}
          alignItems="center"
          sx={{ display: { xs: "flex", md: "none" } }}
        >
          <Grid item xs={12} justifyContent="center" display="flex">
            <Item elevation={16} sx={{ height: "auto", width: "90%" }}>
              <img
                src={`${
                  process.env.REACT_APP_CLOUDINARY_BUCKET +
                  "w_1000/q_auto/f_auto/" +
                  bot_info["robot_img"]
                }`}
                alt={`${bot_info["name"]}`}
                loading="lazy"
                className="ImageSingleRobotInfo"
              />
            </Item>
          </Grid>
          <Grid item xs={12} display="flex" flexDirection="column" width="90%">
            <Grid
              container
              marginTop={2}
              alignItems="stretch"
              direction="column"
              display="flex"
              sx={{ border: 1, borderColor: "#ff0000" }}
            >
              <Grid item xs={12} sx={{ border: 1, borderColor: "#ff0000" }}>
                <Typography
                  varient="h1"
                  noWrap
                  fontSize={50}
                  fontWeight={3}
                  textAlign="center"
                  sx={{ border: 1, borderColor: "#0000ff" }}
                >
                  {bot_info["name"]}
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ border: 1, borderColor: "#ff0000" }}>
                <Link
                  to={`/Teams/${bot_info["team_name"]}`}
                  style={{ textDecoration: "none" }}
                >
                  <Typography
                    varient="h1"
                    fontSize={40}
                    fontWeight={2}
                    textAlign="center"
                    sx={{ border: 1, borderColor: "#0000ff" }}
                  >
                    {bot_info["team_name"]}
                  </Typography>
                </Link>
              </Grid>
              <Grid item xs={12} sx={{ border: 1, borderColor: "#ff0000" }}>
                <Accordion
                  disabled
                  expanded="false"
                  sx={{ justifyContent: "center" }}
                >
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    display="flex"
                    sx={{
                      justifyContent: "center",
                      "& .MuiAccordionSummary-content": {
                        justifyContent: "center",
                      },
                    }}
                  >
                    <Typography variant="h5" textAlign="center">
                      {bot_info["weight"]} kg
                    </Typography>
                  </AccordionSummary>
                </Accordion>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  } else {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={!loaded}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
};

export default RoboDetails;
