import React from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { db } from "../firebase";

import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import CustomizedTables from "../components/tableRobot";

//Icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  flexDirection: "column",
  margin: "8px",
  border: "1px solid red",
}));

function TeamDetails() {
  let { id } = useParams();
  const [loaded, setLoaded] = useState();
  const [team_info, setTeamInfo] = useState();
  const query1 = db.collection("Teams").where(`team_name`, `==`, id);
  const fetchBlogs = async () => {
    const querySnapshot = await query1.get();
    querySnapshot.forEach((item) => {
      setTeamInfo((team_info) => item.data());
    });
    setLoaded((loaded) => 1);
  };
  useEffect(() => {
    fetchBlogs();
  }, 0);
  if (loaded) {
    return (
      <React.Fragment>
        <Helmet>
          <link
            rel="canonical"
            href={`${"https://robowars.co.in/Teams/" + team_info["team_name"]}`}
          />
          <title>{team_info["team_name"]} | Robowars India</title>
          <meta
            name="description"
            content={`${
              team_info["team_name"] +
              " is a outstanding team from " +
              team_info["state"] +
              " participating in combat robotics or robowars event in India "
            }`}
          />
          <meta
            name="keywords"
            content={`${
              "Combat Robotics, Robowars, Robots, Combat Robots, Robowars Robots, Robowars India, Robotics, Robotics India, Live, Techfest, Techinical Fest, Robotics Community, Robotics India, Robotic, Robots, Battlebots, Featherweight, Heavyweight, Lightweight, Hobbyweight, Bettleweight, Events, " +
              team_info["team_name"] +
              ", " +
              team_info["state"]
            }`}
          />
          <meta
            property="og:title"
            content={`${team_info["team_name"] + " | Robowars India"}`}
          />
          <meta
            property="og:url"
            content={`${
              "https://robowars.co.in/Teams/" + team_info["team_name"]
            }`}
          />
          <meta property="og:site_name" content="Robowars India" />
          <meta property="og:type" content="website" />

          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content={`${team_info["team_name"] + " | Robowars India"}`}
          />
          <meta
            name="twitter:description"
            content={`${
              team_info["team_name"] +
              " is a outstanding team from " +
              team_info["state"] +
              " participating in combat robotics or robowars event in India "
            }`}
          />
        </Helmet>
        <Grid
          container
          marginTop={2}
          alignItems="center"
          sx={{ display: { xs: "none", md: "flex" } }}
        >
          <Grid item xs={6}>
            <Item elevation={16} sx={{ height: 500, width: "auto" }}>
              <img
                src={`${
                  process.env.REACT_APP_CLOUDINARY_BUCKET +
                  team_info["team_logo"]
                }`}
                alt={team_info["team_name"]}
                loading="lazy"
                className="ImageSingleRobotInfo"
              />
            </Item>
          </Grid>
          <Grid item xs={6} display="flex" flexDirection="column">
            <Grid
              container
              marginTop={2}
              alignItems="stretch"
              direction="column"
              display="flex"
              sx={{ border: 1, borderColor: "#ff0000" }}
            >
              <Grid item xs={12} sx={{ border: 1, borderColor: "#ff0000" }}>
                <Typography
                  varient="h1"
                  noWrap
                  fontSize={90}
                  fontWeight={3}
                  textAlign="center"
                  sx={{ border: 1, borderColor: "#0000ff" }}
                >
                  {team_info["team_name"]}
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ border: 1, borderColor: "#ff0000" }}>
                <Typography
                  varient="h1"
                  fontSize={80}
                  fontWeight={2}
                  textAlign="center"
                  sx={{ border: 1, borderColor: "#0000ff" }}
                >
                  {team_info["state"]}
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ border: 1, borderColor: "#ff0000" }}>
                <Accordion
                  disabled
                  expanded="false"
                  sx={{ justifyContent: "center" }}
                >
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    display="flex"
                    sx={{
                      justifyContent: "center",
                      "& .MuiAccordionSummary-content": {
                        justifyContent: "center",
                      },
                    }}
                  >
                    <Typography variant="h4" textAlign="center">
                      {team_info["tagline"]}
                    </Typography>
                  </AccordionSummary>
                </Accordion>
              </Grid>
              <Grid item xs={12} sx={{ border: 1, borderColor: "#ff0000" }}>
                <Accordion>
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Typography variant="h4">Contact Details</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography varient="subtitle1">
                      {team_info["email_id"]}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Item elevation={16}>
              <CustomizedTables
                leader={team_info["leader"]}
                active={team_info["active"]}
                deviceHeight={450}
              />
            </Item>
          </Grid>
          <Grid item xs={8}>
            <Item elevation={16} sx={{ height: 600, width: "auto" }}>
              <img
                src={`${
                  process.env.REACT_APP_CLOUDINARY_BUCKET +
                  team_info["team_photo"]
                }`}
                alt={`${team_info["team_name"]}`}
                loading="lazy"
                fit="contain"
                className="ImageTeamPhoto"
              />
            </Item>
          </Grid>
        </Grid>
        <Grid
          container
          marginTop={2}
          alignItems="center"
          sx={{ display: { xs: "flex", md: "none" } }}
        >
          <Grid item xs={12} justifyContent="center" display="flex">
            <Item elevation={16} sx={{ height: "auto", width: "90%" }}>
              <img
                src={`${
                  process.env.REACT_APP_CLOUDINARY_BUCKET +
                  team_info["team_logo"]
                }`}
                alt={team_info["team_name"]}
                loading="lazy"
                className="ImageSingleRobotInfo"
              />
            </Item>
          </Grid>
          <Grid item xs={12} display="flex" flexDirection="column" width="90%">
            <Grid
              container
              marginTop={2}
              alignItems="stretch"
              direction="column"
              display="flex"
              sx={{ border: 1, borderColor: "#ff0000" }}
            >
              <Grid item xs={12} sx={{ border: 1, borderColor: "#ff0000" }}>
                <Typography
                  varient="h1"
                  noWrap
                  fontSize={50}
                  fontWeight={3}
                  textAlign="center"
                  sx={{ border: 1, borderColor: "#0000ff" }}
                >
                  {team_info["team_name"]}
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ border: 1, borderColor: "#ff0000" }}>
                <Typography
                  varient="h1"
                  fontSize={40}
                  fontWeight={2}
                  textAlign="center"
                  sx={{ border: 1, borderColor: "#0000ff" }}
                >
                  {team_info["state"]}
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ border: 1, borderColor: "#ff0000" }}>
                <Accordion
                  disabled
                  expanded="false"
                  sx={{ justifyContent: "center" }}
                >
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    display="flex"
                    sx={{
                      justifyContent: "center",
                      "& .MuiAccordionSummary-content": {
                        justifyContent: "center",
                      },
                    }}
                  >
                    <Typography variant="h5" textAlign="center">
                      {team_info["tagline"]}
                    </Typography>
                  </AccordionSummary>
                </Accordion>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ border: 1, borderColor: "#ff0000" }}>
            <Accordion>
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography variant="h4">Contact Details</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography varient="subtitle1">
                  {team_info["email_id"]}
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12}>
            <Item elevation={16} sx={{ height: "auto", width: "90%" }}>
              <img
                src={`${
                  process.env.REACT_APP_CLOUDINARY_BUCKET +
                  team_info["team_photo"]
                }`}
                alt={`${team_info["team_name"]}`}
                loading="lazy"
                fit="contain"
                className="ImageTeamPhoto"
              />
            </Item>
          </Grid>
          <Grid item xs={12} height={600}>
            <Item elevation={16}>
              <CustomizedTables
                leader={team_info["leader"]}
                active={team_info["active"]}
                deviceHeight={400}
              />
            </Item>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  } else {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={!loaded}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
}

export default TeamDetails;
