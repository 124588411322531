import * as React from "react";
import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import "./robot.css";

import { db } from "../firebase";
import ImageGrid from "../components/ImageGrid";

export default function Robot() {
  const [bots, setBots] = useState([]);
  const [loaded, setLoaded] = useState();
  const [curWeight, setCurWeight] = useState(15);

  const fetchBots = async () => {
    setBots([]);
    const response = db.collection("Robots").where("weight", "==", curWeight);
    const data = await response.get();
    data.docs.forEach((item) => {
      setBots((bots) => [...bots, item.data()]);
    });
    setLoaded((loaded) => 1);
  };

  //Use Effect function for async
  useEffect(() => {
    fetchBots();
  }, [curWeight]);
  if (loaded) {
    return (
      <React.Fragment>
        <Helmet>
          <link rel="canonical" href="https://robowars.co.in/Robots" />
          <title>Robots | Robowars India</title>
          <meta
            name="description"
            content="All Robots from Combat Robotics or Robowars events in India"
          />
          <meta
            name="keywords"
            content="Combat Robotics, Robowars, Robots, Combat Robots, Robowars Robots, Robowars India, Robotics, Robotics India, Live, Techfest, Techinical Fest, Robotics Community, Robotics India, Robotic, Robots, Battlebots, Featherweight, Heavyweight, Lightweight, Hobbyweight, Bettleweight, Events"
          />
          <meta property="og:title" content="Robots | Robowars India" />
          <meta property="og:url" content="https://robowars.co.in/Robots" />
          <meta property="og:site_name" content="Robowars India" />
          <meta property="og:type" content="website" />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Robots | Robowars India" />
          <meta
            name="twitter:description"
            content="Robots from Combat Robotics events in India"
          />
        </Helmet>
        <Grid
          container
          spacing={2}
          marginTop={2}
          marginBottom={2}
          alignItems="center"
          columns={20}
          sx={{ display: { xs: "none", md: "flex" } }}
        >
          {[8, 15, 30, 60, 110].map((item) => (
            <Grid
              item
              xs={4}
              onClick={() => {
                setCurWeight(item);
              }}
            >
              <Button
                varient="outlined"
                fullWidth
                sx={{ height: "45px", padding: "0px" }}
              >
                {item} kg
              </Button>
            </Grid>
          ))}
        </Grid>
        <Grid
          container
          spacing={2}
          marginTop={2}
          marginBottom={2}
          alignItems="center"
          columns={16}
          sx={{ display: { xs: "flex", md: "none" } }}
        >
          {[8, 15, 30, 60].map((item) => (
            <Grid
              item
              xs={8}
              onClick={() => {
                setCurWeight(item);
              }}
            >
              <Button
                varient="outlined"
                fullWidth
                sx={{ height: "45px", padding: "0px" }}
              >
                {item} kg
              </Button>
            </Grid>
          ))}
          <Grid
            item
            xs={16}
            onClick={() => {
              setCurWeight(110);
            }}
          >
            <Button
              varient="outline"
              fullWidth
              sx={{ height: "45px", padding: "0px" }}
            >
              110 kg
            </Button>
          </Grid>
        </Grid>
        <ImageGrid imageList={bots} key={curWeight} />
      </React.Fragment>
    );
  } else {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={!loaded}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
}
