import React from "react";
import { Helmet } from "react-helmet";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { db } from "../firebase";

//MUI stuff
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

//Icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.h1,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  flexDirection: "column",
  margin: "8px",
  border: "1px solid red",
}));

function EventDetails() {
  let { id } = useParams();
  const [blogs, setBlogs] = useState([]);
  const [loaded, setLoaded] = useState();
  const fetchBlogs = async () => {
    const response = db.collection("Events").where("name", "==", id);
    const data = await response.get();
    data.docs.forEach((item) => {
      setBlogs((blogs) => [item.data()]);
    });
    setLoaded((loaded) => 1);
  };

  useEffect(() => {
    fetchBlogs();
  }, []);
  if (loaded) {
    return (
      <React.Fragment>
        <Helmet>
          <link
            rel="canonical"
            href={`${"https://robowars.co.in/Events/" + blogs[0].name}`}
          />
          <title>
            {`${blogs[0].name + " " + blogs[0].year + " | Robowars India"}`}
          </title>
          <meta
            name="description"
            content={`${
              "Robowars event held at " +
              blogs[0].name +
              " , " +
              blogs[0].location +
              " on " +
              blogs[0].startDate
                .toDate()
                .toDateString()
                .split(" ")
                .slice(1)
                .join(" ")
            }`}
          />
          <meta
            name="keywords"
            content={`${
              "Combat Robotics, Robowars, Robots, Events, Team, Combat Robots, Robowars Robots, Robowars India, Robotics, Robotics India, Live, Techfest, Techinical Fest, Robotics Community, Robotics India, Robotic, Robots, Battlebots, Featherweight, Heavyweight, Lightweight, Hobbyweight, Bettleweight, Events, " +
              blogs[0].name +
              ", " +
              blogs[0].location
            }`}
          />
          <meta
            property="og:title"
            content={`${
              blogs[0].name + " " + blogs[0].year + " | Robowars India"
            }`}
          />
          <meta
            property="og:url"
            content={`${"https://robowars.co.in/Events/" + blogs[0].name}`}
          />
          <meta property="og:site_name" content="Robowars India" />
          <meta property="og:type" content="website" />

          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content={`${
              blogs[0].name + " " + blogs[0].year + " | Robowars India"
            }`}
          />
          <meta
            name="twitter:description"
            content={`${
              "Robowars event held at " +
              blogs[0].name +
              " , " +
              blogs[0].location +
              " on " +
              blogs[0].startDate
                .toDate()
                .toDateString()
                .split(" ")
                .slice(1)
                .join(" ")
            }`}
          />
        </Helmet>
        <>
          {blogs.map((blog) => {
            return (
              <React.Fragment>
                <Grid
                  container
                  marginTop={2}
                  alignItems="center"
                  maxWidth="100%"
                  padding={1}
                  sx={{ display: { xs: "none", md: "flex" } }}
                >
                  <Grid item xs={6} padding={2}>
                    <Item elevation={16} sx={{ height: 600, width: "auto" }}>
                      <a href={`https://${blog.url}`}>
                        <img
                          src={
                            process.env.REACT_APP_CLOUDINARY_BUCKET +
                            blog.event_src
                          }
                          alt={blog.banner}
                          loading="lazy"
                          className="ImageSingleRobotInfo"
                        />
                      </a>
                    </Item>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    display="flex"
                    flexDirection="column"
                    padding={2}
                  >
                    <Item elevation={16} sx={{ width: "auto" }}>
                      <Grid
                        container
                        padding={1}
                        alignItems="stretch"
                        direction="column"
                        display="flex"
                        sx={{ border: 1, borderColor: "#ff0000" }}
                      >
                        <Grid
                          item
                          xs={12}
                          sx={{ border: 1, borderColor: "#ff0000" }}
                        >
                          <Typography
                            varient="h1"
                            noWrap
                            fontSize={80}
                            fontWeight={3}
                            textAlign="center"
                          >
                            {blog.name}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{ border: 1, borderColor: "#ff0000" }}
                        >
                          <Typography
                            varient="h1"
                            fontSize={60}
                            fontWeight={2}
                            textAlign="center"
                          >
                            {blog.location}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{ border: 1, borderColor: "#ff0000" }}
                        >
                          <Accordion
                            disabled
                            expanded="false"
                            sx={{ justifyContent: "center" }}
                          >
                            <AccordionSummary
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              display="flex"
                              sx={{
                                justifyContent: "center",
                                "& .MuiAccordionSummary-content": {
                                  justifyContent: "center",
                                },
                              }}
                            >
                              <Typography variant="h4" textAlign="center">
                                {blog.startDate
                                  .toDate()
                                  .toDateString()
                                  .split(" ")
                                  .slice(1)
                                  .join(" ")}
                              </Typography>
                            </AccordionSummary>
                          </Accordion>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid
                            container
                            direction="row"
                            sx={{ height: "100%" }}
                          >
                            {blog.weight.map((element) => {
                              var block_size = 12 / blog.weight.length;
                              return (
                                <Grid
                                  item
                                  xs={block_size}
                                  direction="row"
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                  padding={2}
                                  sx={{
                                    border: 1,
                                    borderColor: "#ff0000",
                                  }}
                                >
                                  <Typography variant="h4">
                                    {element}
                                  </Typography>
                                </Grid>
                              );
                            })}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Item>
                  </Grid>

                  {/* Arena Specification*/}

                  <Grid
                    item
                    xs={4}
                    display="flex"
                    flexDirection="column"
                    padding={2}
                  >
                    <Item elevation={16} sx={{ width: "auto" }}>
                      <Grid
                        container
                        alignItems="stretch"
                        direction="column"
                        display="flex"
                        padding={1}
                        sx={{ border: 1, borderColor: "#ff0000" }}
                      >
                        <Grid
                          item
                          xs={12}
                          sx={{ border: 1, borderColor: "#ff0000" }}
                        >
                          <Typography
                            varient="h1"
                            noWrap
                            fontSize={80}
                            fontWeight={3}
                            textAlign="center"
                          >
                            Arena
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          direction="row"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          padding={2}
                          sx={{
                            border: 1,
                            borderColor: "#ff0000",
                          }}
                        >
                          <Typography variant="h4">{blog.arena}</Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{ border: 1, borderColor: "#ff0000" }}
                        >
                          <Accordion
                            disabled
                            expanded="false"
                            sx={{ justifyContent: "center" }}
                          >
                            <AccordionSummary
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              display="flex"
                              sx={{
                                justifyContent: "center",
                                "& .MuiAccordionSummary-content": {
                                  justifyContent: "center",
                                },
                              }}
                            >
                              <Typography variant="h4" textAlign="center">
                                {blog.arenaSize}
                              </Typography>
                            </AccordionSummary>
                          </Accordion>
                        </Grid>
                      </Grid>
                    </Item>
                  </Grid>

                  {/* Judge Specification*/}

                  <Grid
                    item
                    xs={4}
                    display="flex"
                    flexDirection="column"
                    padding={2}
                  >
                    <Item elevation={16} sx={{ width: "auto" }}>
                      <Grid
                        container
                        alignItems="stretch"
                        direction="column"
                        display="flex"
                        sx={{ border: 1, borderColor: "#ff0000" }}
                      >
                        <Grid
                          item
                          xs={12}
                          sx={{ border: 1, borderColor: "#ff0000" }}
                        >
                          <Typography
                            varient="h1"
                            noWrap
                            fontSize={80}
                            fontWeight={3}
                            textAlign="center"
                          >
                            Judges
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid
                            container
                            direction="row"
                            sx={{ height: "100%" }}
                          >
                            {blog.judge.map((element) => {
                              return (
                                <Grid
                                  item
                                  xs={12}
                                  direction="row"
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                  padding={2}
                                  sx={{
                                    border: 1,
                                    borderColor: "#ff0000",
                                  }}
                                >
                                  <Typography variant="h4">
                                    {element}
                                  </Typography>
                                </Grid>
                              );
                            })}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Item>
                  </Grid>

                  {/* Prize Specification*/}

                  <Grid
                    item
                    xs={4}
                    display="flex"
                    flexDirection="column"
                    padding={2}
                  >
                    <Item elevation={16} sx={{ width: "auto" }}>
                      <Grid
                        container
                        alignItems="stretch"
                        direction="column"
                        display="flex"
                        padding={1}
                        sx={{ border: 1, borderColor: "#ff0000" }}
                      >
                        <Grid
                          item
                          xs={12}
                          sx={{ border: 1, borderColor: "#ff0000" }}
                        >
                          <Typography
                            varient="h1"
                            noWrap
                            fontSize={80}
                            fontWeight={3}
                            textAlign="center"
                          >
                            Prize
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{ border: 1, borderColor: "#ff0000" }}
                        >
                          <Typography
                            varient="h1"
                            fontSize={40}
                            fontWeight={2}
                            textAlign="center"
                          >
                            Total : {blog.prize}
                          </Typography>
                        </Grid>
                        {blog.prize !== blog.cashPrize ? (
                          <React.Fragment>
                            <Grid
                              item
                              xs={12}
                              sx={{ border: 1, borderColor: "#ff0000" }}
                            >
                              <Typography
                                varient="h1"
                                fontSize={40}
                                fontWeight={2}
                                textAlign="center"
                              >
                                Cash : {blog.cashPrize}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sx={{ border: 1, borderColor: "#ff0000" }}
                            >
                              <Typography
                                varient="h1"
                                fontSize={40}
                                fontWeight={2}
                                textAlign="center"
                              >
                                {blog.otherPrize}
                              </Typography>
                            </Grid>
                          </React.Fragment>
                        ) : null}
                        {blog.startDate["seconds"] * 1000 < Date.now() ? (
                          <Grid
                            item
                            xs={12}
                            sx={{ border: 1, borderColor: "#ff0000" }}
                          >
                            <Accordion
                              disabled
                              expanded="false"
                              sx={{ justifyContent: "center" }}
                            >
                              <AccordionSummary
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                display="flex"
                                sx={{
                                  justifyContent: "center",
                                  "& .MuiAccordionSummary-content": {
                                    justifyContent: "center",
                                  },
                                }}
                              >
                                <Typography variant="h4" textAlign="center">
                                  Status :{" "}
                                  {blog.prizePaid === true
                                    ? "Paid in full"
                                    : "Not Paid"}
                                </Typography>
                              </AccordionSummary>
                            </Accordion>
                          </Grid>
                        ) : null}
                      </Grid>
                    </Item>
                  </Grid>

                  {/* Fixtures Specification*/}
                  {blog.startDate["seconds"] * 1000 < Date.now() &&
                  blog.bracket[0] !== "None" ? (
                    <Grid
                      item
                      xs={12}
                      display="flex"
                      flexDirection="column"
                      padding={2}
                    >
                      <Item elevation={16} sx={{ width: "auto" }}>
                        <Grid
                          container
                          alignItems="stretch"
                          direction="column"
                          display="flex"
                          sx={{ border: 1, borderColor: "#ff0000" }}
                        >
                          <Grid
                            item
                            xs={12}
                            sx={{ border: 1, borderColor: "#ff0000" }}
                          >
                            <Typography
                              varient="h1"
                              noWrap
                              fontSize={80}
                              fontWeight={3}
                              textAlign="center"
                            >
                              Brackets
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid
                              container
                              direction="row"
                              sx={{ height: "100%" }}
                            >
                              {blog.bracket.map((element) => {
                                return (
                                  <Grid
                                    item
                                    xs={12}
                                    direction="row"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    padding={2}
                                    sx={{
                                      border: 1,
                                      borderColor: "#ff0000",
                                    }}
                                  >
                                    <iframe
                                      src={element}
                                      title="Brackets"
                                      width="100%"
                                      height="550"
                                      frameborder="0"
                                      onLoad={() => {
                                        setLoaded((loaded) => 1);
                                      }}
                                      scrolling="auto"
                                      allowtransparency="true"
                                    ></iframe>
                                  </Grid>
                                );
                              })}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Item>
                    </Grid>
                  ) : null}
                </Grid>

                {/* Mobile Varient */}

                <Grid
                  container
                  marginTop={2}
                  alignItems="center"
                  padding={2}
                  sx={{ display: { xs: "flex", md: "none" } }}
                >
                  <Grid item xs={12} justifyContent="center" display="flex">
                    <Item elevation={16} sx={{ height: "auto", width: "100%" }}>
                      <a href={`https://${blog.url}`}>
                        <img
                          src={
                            process.env.REACT_APP_CLOUDINARY_BUCKET +
                            blog.event_src
                          }
                          alt={blog.banner}
                          loading="lazy"
                          className="ImageSingleRobotInfo"
                        />
                      </a>
                    </Item>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    display="flex"
                    flexDirection="column"
                    width="90%"
                  >
                    <Item elevation={16} sx={{ width: "auto" }}>
                      <Grid
                        container
                        padding={1}
                        alignItems="stretch"
                        direction="column"
                        display="flex"
                      >
                        <Grid
                          item
                          xs={12}
                          sx={{ border: 1, borderColor: "#ff0000" }}
                        >
                          <Typography
                            varient="h1"
                            noWrap
                            fontSize={40}
                            fontWeight={3}
                            textAlign="center"
                          >
                            {blog.name}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{ border: 1, borderColor: "#ff0000" }}
                        >
                          <Typography
                            varient="h1"
                            fontSize={30}
                            fontWeight={2}
                            textAlign="center"
                          >
                            {blog.location}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{ border: 1, borderColor: "#ff0000" }}
                        >
                          <Accordion
                            disabled
                            expanded="false"
                            sx={{ justifyContent: "center" }}
                          >
                            <AccordionSummary
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              display="flex"
                              sx={{
                                justifyContent: "center",
                                "& .MuiAccordionSummary-content": {
                                  justifyContent: "center",
                                },
                              }}
                            >
                              <Typography variant="h5" textAlign="center">
                                {blog.startDate
                                  .toDate()
                                  .toDateString()
                                  .split(" ")
                                  .slice(1)
                                  .join(" ")}
                              </Typography>
                            </AccordionSummary>
                          </Accordion>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid
                            container
                            direction="row"
                            sx={{ height: "100%" }}
                          >
                            {blog.weight.map((element) => {
                              var block_size = 12 / blog.weight.length;
                              return (
                                <Grid
                                  item
                                  xs={block_size}
                                  direction="row"
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                  padding={2}
                                  sx={{
                                    border: 1,
                                    borderColor: "#ff0000",
                                  }}
                                >
                                  <Typography variant="h5">
                                    {element}
                                  </Typography>
                                </Grid>
                              );
                            })}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Item>
                  </Grid>

                  {/* Arena Specification*/}

                  <Grid item xs={12} display="flex" flexDirection="column">
                    <Item elevation={16} sx={{ width: "auto" }}>
                      <Grid
                        container
                        alignItems="stretch"
                        direction="column"
                        display="flex"
                        padding={1}
                        sx={{ border: 1, borderColor: "#ff0000" }}
                      >
                        <Grid
                          item
                          xs={12}
                          sx={{ border: 1, borderColor: "#ff0000" }}
                        >
                          <Typography
                            varient="h1"
                            noWrap
                            fontSize={49}
                            fontWeight={3}
                            textAlign="center"
                          >
                            Arena
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          direction="row"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          padding={1}
                          sx={{
                            border: 1,
                            borderColor: "#ff0000",
                          }}
                        >
                          <Typography variant="h5">{blog.arena}</Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{ border: 1, borderColor: "#ff0000" }}
                        >
                          <Accordion
                            disabled
                            expanded="false"
                            sx={{ justifyContent: "center" }}
                          >
                            <AccordionSummary
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              display="flex"
                              sx={{
                                justifyContent: "center",
                                "& .MuiAccordionSummary-content": {
                                  justifyContent: "center",
                                },
                              }}
                            >
                              <Typography variant="h5" textAlign="center">
                                {blog.arenaSize}
                              </Typography>
                            </AccordionSummary>
                          </Accordion>
                        </Grid>
                      </Grid>
                    </Item>
                  </Grid>

                  {/* Judge Specification*/}

                  <Grid item xs={12} display="flex" flexDirection="column">
                    <Item elevation={16} sx={{ width: "auto" }}>
                      <Grid
                        container
                        alignItems="stretch"
                        direction="column"
                        display="flex"
                        sx={{ border: 1, borderColor: "#ff0000" }}
                      >
                        <Grid
                          item
                          xs={12}
                          sx={{ border: 1, borderColor: "#ff0000" }}
                        >
                          <Typography
                            varient="h1"
                            noWrap
                            fontSize={40}
                            fontWeight={3}
                            textAlign="center"
                          >
                            Judges
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid
                            container
                            direction="row"
                            sx={{ height: "100%" }}
                          >
                            {blog.judge.map((element) => {
                              return (
                                <Grid
                                  item
                                  xs={12}
                                  direction="row"
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                  padding={1}
                                  sx={{
                                    border: 1,
                                    borderColor: "#ff0000",
                                  }}
                                >
                                  <Typography variant="h5">
                                    {element}
                                  </Typography>
                                </Grid>
                              );
                            })}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Item>
                  </Grid>

                  {/* Prize Specification*/}

                  <Grid item xs={12} display="flex" flexDirection="column">
                    <Item elevation={16} sx={{ width: "auto" }}>
                      <Grid
                        container
                        alignItems="stretch"
                        direction="column"
                        display="flex"
                        padding={1}
                        sx={{ border: 1, borderColor: "#ff0000" }}
                      >
                        <Grid
                          item
                          xs={12}
                          sx={{ border: 1, borderColor: "#ff0000" }}
                        >
                          <Typography
                            varient="h1"
                            noWrap
                            fontSize={40}
                            fontWeight={3}
                            textAlign="center"
                          >
                            Prize
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{ border: 1, borderColor: "#ff0000" }}
                        >
                          <Typography
                            varient="h1"
                            fontSize={30}
                            fontWeight={2}
                            textAlign="center"
                          >
                            Total : {blog.prize}
                          </Typography>
                        </Grid>
                        {blog.prize !== blog.cashPrize ? (
                          <React.Fragment>
                            <Grid
                              item
                              xs={12}
                              sx={{ border: 1, borderColor: "#ff0000" }}
                            >
                              <Typography
                                varient="h1"
                                fontSize={30}
                                fontWeight={2}
                                textAlign="center"
                              >
                                Cash : {blog.cashPrize}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sx={{ border: 1, borderColor: "#ff0000" }}
                            >
                              <Typography
                                varient="h1"
                                fontSize={30}
                                fontWeight={2}
                                textAlign="center"
                              >
                                {blog.otherPrize}
                              </Typography>
                            </Grid>
                          </React.Fragment>
                        ) : null}
                        {blog.startDate["seconds"] * 1000 < Date.now() ? (
                          <Grid
                            item
                            xs={12}
                            sx={{ border: 1, borderColor: "#ff0000" }}
                          >
                            <Accordion
                              disabled
                              expanded="false"
                              sx={{ justifyContent: "center" }}
                            >
                              <AccordionSummary
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                display="flex"
                                sx={{
                                  justifyContent: "center",
                                  "& .MuiAccordionSummary-content": {
                                    justifyContent: "center",
                                  },
                                }}
                              >
                                <Typography variant="h5" textAlign="center">
                                  Status :{" "}
                                  {blog.prizePaid === true
                                    ? "Paid in full"
                                    : "Not Paid"}
                                </Typography>
                              </AccordionSummary>
                            </Accordion>
                          </Grid>
                        ) : null}
                      </Grid>
                    </Item>
                  </Grid>

                  {/* Fixtures Specification*/}
                  {blog.startDate["seconds"] * 1000 < Date.now() &&
                  blog.bracket[0] !== "None" ? (
                    <Grid
                      item
                      xs={12}
                      display="flex"
                      flexDirection="column"
                      padding={2}
                    >
                      <Item elevation={16} sx={{ width: "auto" }}>
                        <Grid
                          container
                          alignItems="stretch"
                          direction="column"
                          display="flex"
                          sx={{ border: 1, borderColor: "#ff0000" }}
                        >
                          <Grid
                            item
                            xs={12}
                            sx={{ border: 1, borderColor: "#ff0000" }}
                          >
                            <Typography
                              varient="h1"
                              noWrap
                              fontSize={40}
                              fontWeight={3}
                              textAlign="center"
                            >
                              Brackets
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid
                              container
                              direction="row"
                              sx={{ height: "100%" }}
                            >
                              {blog.bracket.map((element) => {
                                return (
                                  <Grid
                                    item
                                    xs={12}
                                    direction="row"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    padding={2}
                                    sx={{
                                      border: 1,
                                      borderColor: "#ff0000",
                                    }}
                                  >
                                    <iframe
                                      src={element}
                                      title="Brackets"
                                      width="100%"
                                      height="450"
                                      frameborder="0"
                                      onLoad={() => {
                                        setLoaded((loaded) => 1);
                                      }}
                                      scrolling="auto"
                                      allowtransparency="true"
                                    ></iframe>
                                  </Grid>
                                );
                              })}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Item>
                    </Grid>
                  ) : null}
                </Grid>
              </React.Fragment>
            );
          })}
        </>
      </React.Fragment>
    );
  } else {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={!loaded}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
}

export default EventDetails;
