import React from "react";
import { Helmet } from "react-helmet";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";

function createData(name, weight, dimensions) {
  return { name, weight, dimensions };
}

const rows = [
  createData("Hobbyweight", 8, "400 x 400 x 400 mm"),
  createData("Featherweight", 15, "500 x 500 x 500 mm"),
  createData("Lightweight", 30, "500 x 500 x 500 mm"),
  createData("Middleweight", 60, "1000 x 1000 x 1000 mm"),
  createData("Heavyweight", 110, "1000 x 1000 x 1000 mm"),
];

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Rulebook = () => {
  return (
    <React.Fragment>
      <Helmet>
        <link rel="canonical" href="https://robowars.co.in/Rulebook" />
        <title>Rulebook | Robowars India</title>
        <meta
          name="description"
          content="Robowars Database. Community project driven by Passion"
        />
        <meta property="og:title" content="Rulebook | Robowars India" />
        <meta property="og:url" content="https://robowars.co.in/Rulebook" />
        <meta property="og:site_name" content="Robowars India" />
        <meta property="og:type" content="website" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Rulebook | Robowars India" />
        <meta
          name="twitter:description"
          content="Rulebook | Robowars Database. Community project driven by Passion"
        />
      </Helmet>
      <Grid
        container
        marginTop={2}
        alignItems="center"
        sx={{ display: { xs: "none", md: "flex" } }}
      >
        <Grid item xs={2} />
        <Grid item xs={8}>
          <Paper
            sx={{
              padding: 2,
              margin: "auto",
              maxWidth: 900,
              flexGrow: 1,
              backgroundColor: (theme) =>
                theme.palette.mode === "dark" ? "#1A2027" : "#fff",
            }}
          >
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              sx={{ display: { xs: "none", md: "flex" } }}
            >
              <Grid
                item
                xs={12}
                justifyContent="center"
                alignItems="center"
                display="flex"
              >
                <Typography
                  varient="h1"
                  fontSize="4rem"
                  fontFamily="sans-serif"
                >
                  Standard Rulebook
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                justifyContent="center"
                alignItems="center"
                display="flex"
              >
                <Typography
                  varient="h1"
                  fontSize="1rem"
                  fontFamily="sans-serif"
                >
                  We deem VIT Rulebook to be very standard and hence we are
                  using that as an reference
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              display="flex"
              marginTop={2}
            >
              <Typography varient="h1" fontSize="3rem" align="justify">
                Design specifications
              </Typography>
            </Grid>
            <Divider />
            <Grid
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              display="flex"
              marginTop={2}
            >
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Class</TableCell>
                      <TableCell align="center">Weight</TableCell>
                      <TableCell align="center">Dimensions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow
                        key={row.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row" align="center">
                          {row.name}
                        </TableCell>
                        <TableCell align="center">{row.weight} kg</TableCell>
                        <TableCell align="center">{row.dimensions}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              display="flex"
              marginTop={2}
            >
              <Typography align="justify">
                All categories permits all kinds of weapons specified in the
                rulebook. The machine should not exceed the above
                specifications. Any external device used to control the machine
                is not included in the dimension limits. All the batteries
                should be on-board, only the weight of the remote controller
                will not be included. However an extra tolerance of 2cm in
                dimensions is allowed. The weight of the bot will be checked
                before and/or after every match. A bot can be in a "Cluster Bot"
                formation. Each bot must meet the requirements described in this
                problem statement. The total weight of all the bots must not
                cross the limits in the above point. Participating teams having
                multiple bots can compete in their combined weight category,
                i.e., for example two 30kg bots of the same team can compete in
                the 60kg weight category
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              display="flex"
              marginTop={2}
            >
              <Typography varient="h1" fontSize="3rem" align="justify">
                Mobility
              </Typography>
            </Grid>
            <Divider />
            <Grid
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              display="flex"
              marginTop={2}
            >
              <Typography align="justify">
                All robots must have easily visible and controlled mobility in
                order to compete. Methods of mobility include:
                <ul>
                  <li>Rolling (wheels, tracks or the whole robot).</li>
                  <li>
                    Non-wheeled: Non-wheeled robots have no rolling elements in
                    contact with the floor and have no continuous rolling or Cam
                    Operated motion in contact with the floor, either directly
                    or via a linkage, but are not true walkers. Motion is
                    continuous if continuous operation of the drive motor(s)
                    produces continuous motion of the robot. Linear-actuated
                    legs and novel non wheeled drive systems are allowed under
                    this category.
                  </li>
                  <li>
                    Jumping and hopping is allowed (manually operated). However,
                    the maximum height of any part of the machine should not
                    exceed 6ft during any stage of its jumping/hopping and any
                    damage caused due to its mechanism as well as the arena is
                    responsible to the team.
                  </li>
                </ul>
                Mobility methods that are <strong>NOT</strong> allowed:
                <ul>
                  <li>
                    Flying(using airfoil,helium balloons, etc) are not allowed.{" "}
                  </li>
                  <li>
                    Robots should not secure themselves on the ring by using
                    suction cups, etc
                  </li>
                </ul>
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              display="flex"
              marginTop={2}
            >
              <Typography varient="h1" fontSize="3rem" align="justify">
                Robot Control Requirements
              </Typography>
            </Grid>
            <Divider />
            <Grid
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              display="flex"
              marginTop={2}
            >
              <Typography align="justify">
                <ul>
                  <li>
                    All robots must be controlled through
                    <strong>wireless remote only</strong>, and all power supply
                    must be on-board only.
                    <strong>
                      No external connections (wired) will be allowed during
                      matches.
                    </strong>
                  </li>
                  <li>
                    Control must be exhibited over all of its functions and
                    positions at all times and should be displayed even during
                    matches at the say-so of the judges/organizing team.
                  </li>
                  <li>
                    Although Autonomous functions within the bot are acceptable,
                    but the controller must be able to remotely disable or
                    override those functions at any time. Also note that any
                    damage due to this function is a responsibility of the team,
                    and there must compulsorily be a{" "}
                    <strong>manual emergency stop</strong>
                    (E-stop) function that should be controlled from the radio
                    controller to manually override the autonomous function in
                    case of emergency. Such an emergency shall be declared by
                    the judge.
                  </li>
                  <li>
                    The transmitter-receiver should be able to work through
                    polycarbonate sheets, metal bars and barriers. Remote
                    Controllers with such facilities will only be allowed.
                  </li>
                  <li>
                    The team must have at least four frequency wireless remote
                    control circuits or two dual control circuits which may be
                    interchanged before the start of the match to avoid
                    frequency interference with other teams. The case of any
                    interference in the wireless system will not be considered
                    for rematch or results.
                  </li>
                  <li>
                    Remote control systems available in the market can be used,
                    While nonstandard or self-made remote control systems must
                    first be approved by the organizers and judges.
                  </li>
                  <li>
                    Team should pair up the wireless remote with the robot
                    before putting it into the arena. No extra time will be
                    provided for pairing of transmitter and receiver once the
                    robots are put inside the arena, and not connecting it prior
                    to that may lead to Disqualification.
                  </li>
                </ul>
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              display="flex"
              marginTop={2}
            >
              <Typography varient="h1" fontSize="3rem" align="justify">
                Battery and Power
              </Typography>
            </Grid>
            <Divider />
            <Grid
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              display="flex"
              marginTop={2}
            >
              <Typography align="justify">
                <ul>
                  <li>
                    The robot should be powered electrically only. Use of IC
                    engines in any form is not allowed. On board batteries must
                    be sealed and immobilized-electrolyte types of batteries
                    should be used (such as gel cells, lithium, NiCad, NiMH, or
                    dry cells).{" "}
                  </li>
                  <li>
                    The electric voltage between 2 points anywhere in the
                    machine should not be more than 48V DC at any point of time.
                    Participants will have to bring their own converters for
                    standard power supply according to Indian standards. All
                    efforts must be made to protect battery terminals from a
                    direct short and causing a battery fire, failure to do so
                    will cause direct disqualification. In case of any
                    emergency, the participants must stay calm and stay in the
                    respective corners, the organizers will only be allowed
                    inside the arena.
                  </li>
                  <li>
                    Use of damaged, non-leak proof batteries may lead to
                    disqualification.{" "}
                  </li>
                  <li>
                    Special care should be taken to protect the on-board
                    batteries. If judges find the battery is not properly
                    protected, then the team will be disqualified immediately.{" "}
                  </li>
                  <li>
                    Change of battery will not be allowed during the match.
                  </li>
                  <li>
                    Robots with only <strong>on board batteries</strong> are
                    allowed.
                  </li>
                  <li>
                    It is suggested to have extra batteries ready and charged up
                    during the competition so that on advancing to the next
                    round, there is no delay. If teams don't show up on allotted
                    slots within the specified time limit, they will be
                    disqualified.
                  </li>
                </ul>
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              display="flex"
              marginTop={2}
            >
              <Typography varient="h1" fontSize="3rem" align="justify">
                Pneumatics
              </Typography>
            </Grid>
            <Divider />
            <Grid
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              display="flex"
              marginTop={2}
            >
              <Typography align="justify">
                <ul>
                  <li>
                    Robots can use pressurized non-flammable gasses to actuate
                    pneumatic devices. Maximum allowed outlet nozzle pressure is
                    50 bar. The storage tank and pressure used by the team needs
                    to be certified and teams using pneumatics are required to
                    produce the Safety and Security letter at the Registration
                    desk at the venue. Failing to do so will lead to direct
                    disqualification.{" "}
                  </li>
                  <li>
                    Participants must be able to indicate the used pressure with
                    an integrated or temporarily fitted pressure gauge. Also
                    there should be provision to check the cylinder pressure on
                    the bot.{" "}
                  </li>
                  <li>
                    The maximum pressure in the cylinder should not exceed the
                    rated pressure at any point of time. All the pneumatic
                    components must be rated at least value of maximum pressure.{" "}
                  </li>
                  <li>
                    You must have a safe way of refilling the system and
                    determining the on board pressure.
                  </li>
                  <li>
                    All pneumatic components on board a robot must be securely
                    mounted. Care must be taken while mounting the pressure
                    vessel and armor to ensure that, if ruptured it will not
                    spread out of the robot. The terms pressure vessel bottle,
                    and source tank are used interchangeably.{" "}
                  </li>
                  <li>
                    Entire pneumatic setup should be on board, no external input
                    (from outside the arena) should be given to the robot for
                    functioning of its pneumatic system.
                  </li>
                </ul>
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              display="flex"
              marginTop={2}
            >
              <Typography varient="h1" fontSize="3rem" align="justify">
                Weapon Systems
              </Typography>
            </Grid>
            <Divider />
            <Grid
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              display="flex"
              marginTop={2}
            >
              <Typography align="justify">
                Robots can have any kind of magnetic weapons, cutters, flippers,
                saws, lifting devices, spinning hammers etc. as weapons(if they
                qualify the criteria mentioned below). <br /> <br />
                All the weapons must have a safety cover over any sharp edges,
                all weapons should have a <strong>weapon lock</strong> to
                restrict the motion of the weapon when the bot is not in the
                arena and it should be removed only when the bot is ready on the
                arena. <br /> <br />
                Following weapons are exceptions and should not be used:All
                robots must have easily visible and controlled mobility in order
                to compete. Methods of mobility include: <br /> <br />
                <ul>
                  <li>
                    Solid and Liquid projectiles (bullets, foam, liquefied
                    gasses,water, flame thrower){" "}
                  </li>
                  <li>
                    Weapons causing invisible damage (Electrical weapon, RF
                    jamming weapons and others)
                  </li>
                  <li>
                    Spinning Weapons:
                    <ul>
                      <li>
                        Spinning weapons must come to a halt within 60 seconds
                        of the power being removed using a self-contained
                        braking-system.
                      </li>
                      <li>
                        Spinning weapons must only be tested in the arena,
                        failing to adhere to this rule will lead to
                        disqualification of the robot.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Spring-Loaded or flywheels:
                    <ul>
                      <li>
                        The spring must always be free and unloaded, only during
                        the match the spring must be loaded inside the arena.
                        All testing must be done in the arena only under the
                        supervision of the organizing team.
                      </li>
                      <li>
                        All springs, flywheels, and similar kinetic energy
                        storing devices must be controllable at all times. Any
                        intended/unintended movement outside the arena or near
                        the public may lead to disqualification of the team
                        under safety violation.
                      </li>
                    </ul>
                  </li>
                </ul>
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              display="flex"
              marginTop={2}
            >
              <Typography varient="h1" fontSize="3rem" align="justify">
                Team Specifications
              </Typography>
            </Grid>
            <Divider />
            <Grid
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              display="flex"
              marginTop={2}
            >
              <Typography align="justify">
                <ul>
                  <li>
                    A team can consist of a maximum of 6 participants per bot.
                    These participants can be from the same or different
                    institutes.
                  </li>
                  <li>
                    Team Name: Every team must have a unique name. The
                    organizing team reserves the right to reject entries from
                    any team whose name it deems inappropriate, offensive or
                    conflicting. Organizers must be notified if a team's name
                    has been changed.
                  </li>
                  <li>
                    Team Representative: Each team must specify their Team
                    Representative (Leader) at the time of registration on the
                    website. All important communications between organizing
                    team and the registered teams will be done through their
                    team representative. The team representative must submit
                    valid contact details (phone no, email ID, etc) at the time
                    of registration.
                  </li>
                  <li>
                    Robot's name cannot be changed during the whole course of
                    the event. Any changes in the design should be informed to
                    the coordinators/organizing team.
                  </li>
                  <li>
                    Robot name and photo should be submitted during the
                    registrations. The submitted details will be cross-checked
                    anytime during the entire course of the event.
                  </li>
                  <li>
                    If a team has more than one robot in a single category, they
                    must be visually distinguishable, this shall be checked at
                    the start of the tournament, and any deviation from the
                    original shall be punished with disqualification (Discretion
                    of the judges).
                  </li>
                  <li>
                    If a participating team is entering two bots in the same
                    category, it's required that each bot has a distinct pilot,
                    the same pilot cannot operate both bots.
                  </li>
                  <li>
                    In the event that two bots belong to the same participating
                    team, it is imperative that their components and parts
                    exhibit noticeable distinctions. Failure to achieve this can
                    result in potential disqualification.
                  </li>
                  <li>
                    All participating teams must present an abstract of the bot
                    along with the bot that they are competing with, one, two or
                    many.
                  </li>
                  <li>
                    All bots will be inspected according to the abstract
                    submitted by the team and it should meet the specifications
                    produced by the respective team.
                  </li>
                  <li>
                    All participating teams must have their bots completely
                    assembled 30 mins prior to the start of the competition
                  </li>
                </ul>
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              display="flex"
              marginTop={2}
            >
              <Typography varient="h1" fontSize="3rem" align="justify">
                Tournament Profile
              </Typography>
            </Grid>
            <Divider />
            <Grid
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              display="flex"
              marginTop={2}
            >
              <Typography align="justify">
                <ul>
                  <li>
                    Double Elimination form of tournament will be followed in
                    the competition.
                  </li>
                  <li>
                    The fixtures will be drawn by the judges in the presence of
                    the organizing team to ensure a fair process. This will be
                    done using a chit system to assign opponents in the
                    fixtures.
                  </li>
                  <li>
                    Fixtures will not be changed under any circumstances. Any
                    request to do so will not be entertained by the organizing
                    team.
                  </li>
                </ul>
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              display="flex"
              marginTop={2}
            >
              <Typography varient="h1" fontSize="3rem" align="justify">
                Match Frequency
              </Typography>
            </Grid>
            <Divider />
            <Grid
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              display="flex"
              marginTop={2}
            >
              <Typography align="justify">
                Each team shall be given 30 minutes between matches (Organizers
                reserve the right to change this duration on the day of the
                competition).This time is calculated from the time the robot
                leaves the post match staging area. If the team fails to return,
                with the robot ready, to the prematch staging area when called
                after the allotted time, the team will be forced to forfeit as
                per the discretion of the judges and organizers. <br /> <br />
                Matches will be 3 minutes of active fight time exclusive of any
                time-outs. Hence, it is not binding but advisable to keep
                battery capacity, power usage and machine defenses such that it
                should sustain a 3 minute fight. <br /> <br />
                The team members are not allowed inside the arena between the
                matches, if a bot stops working it will be declared immobile.
                Only if judges find that bots are stuck together and have no
                movement from any side then we will give 20-30 sec to let them
                separate by themselves under the say so and supervision of a
                judge/organizing team. <br /> <br />
                It is recommended that any routine maintenance (i.e. battery
                charging) should be capable of being performed well within this
                time period, or backup should be kept. In extreme cases the 30
                minutes time period may be lengthened at the discretion of the
                judges and event organizers. The weight of the bot will be
                checked before and/or after every match.
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              display="flex"
              marginTop={2}
            >
              <Typography varient="h1" fontSize="3rem" align="justify">
                Pre-Match Protocols
              </Typography>
            </Grid>
            <Divider />
            <Grid
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              display="flex"
              marginTop={2}
            >
              <Typography align="justify">
                <ul>
                  <li>
                    Bots safety will be checked 1 night prior to the
                    competition. This will be verified again before the match
                    starts.{" "}
                  </li>
                  <li>
                    Robot should be ready, functional and near the arena 15 mins
                    prior to the final call for the match.{" "}
                  </li>
                  <li>
                    Participating teams must adhere to a strict 0% weight
                    tolerance, ensuring that the bot's total weight remains
                    within the specified limits at all times.
                  </li>
                </ul>
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              display="flex"
              marginTop={2}
            >
              <Typography varient="h1" fontSize="3rem" align="justify">
                Protocols during Match
              </Typography>
            </Grid>
            <Divider />
            <Grid
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              display="flex"
              marginTop={2}
            >
              <Typography align="justify">
                <ul>
                  <li>
                    During the match, a maximum of 2 people will be allowed near
                    the arena on the robot driver's stage. Rest of the team must
                    stay away from the arena and in the proper place designated
                    by the organizing team.{" "}
                  </li>
                  <li>
                    Team members must not enter the arena under any
                    circumstances. The organizing team will handle any mishap
                    and only after the organizing team ensures the safety, teams
                    can be allowed into the arena to retrieve their robots.
                    Failing to follow this will lead to direct elimination
                    irrespective of the match outcome (discretion of organizers){" "}
                  </li>
                  <li>
                    {" "}
                    The Weapon and the Robot should start moving only after the
                    countdown is fully completed. If it is started in between
                    the countdown, the judges and the organizing team has the
                    right to restart the match or disqualify the team.
                  </li>
                </ul>
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              display="flex"
              marginTop={2}
            >
              <Typography varient="h1" fontSize="3rem" align="justify">
                Criteria for Victory
              </Typography>
            </Grid>
            <Divider />
            <Grid
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              display="flex"
              marginTop={2}
            >
              <Typography align="justify">
                <ul>
                  <li>
                    A robot is declared victorious if its opponent is
                    immobilized.{" "}
                  </li>
                  <li>
                    A robot will be declared immobile if it can not display
                    linear motion of at least one inch in a timed period of 10
                    seconds or as decided by the judges. A bot with one side of
                    its drivetrain disabled will not be counted out if it can
                    demonstrate some degree of controlled movement. In such a
                    case, if appealed by the opposing team and the judges deem
                    it necessary, the match should be halted to check the
                    controlled movement of the robot, if the control of the bot
                    is satisfactory to the judges, the match will continue or
                    else the match will be ended and the winner declared.{" "}
                  </li>
                  <li>
                    A robot that is deemed unsafe by the judges after the match
                    has begun will be disqualified and therefore declared the
                    loser. The match will be immediately halted and the opponent
                    will be awarded a win.{" "}
                  </li>
                  <li>
                    If a robot is thrown out of the Arena, and is not able to
                    come back in, the match will stop immediately, and the robot
                    still inside the Arena will automatically be declared as the
                    winner. If a robot is immobilized on the side of the arena,
                    the weapon must be stopped immediately. Causing damage to
                    the arena intentionally shall be penalized.{" "}
                  </li>
                  <li>
                    Robots can not win by pinning or lifting their opponents.
                    Organizers will allow pinning or lifting for a maximum of 20
                    seconds per pin lift then the attacker robot will be
                    instructed to release the opponent. If, after being
                    instructed to do so, the attacker is able to release but
                    does not, their robot may be disqualified. If two or more
                    robots become entangled or a crushing or gripping weapon is
                    employed and are trapped within another robot, then the
                    competitors can make the timekeeper aware, the fight should
                    be stopped and the robots separated by the safest means at
                    the say so of the judges
                  </li>
                  <li>
                    Points will be given on the basis of aggression, damage,
                    control, and strategy.{" "}
                    <ul>
                      <li>
                        Aggression: Aggression is judged by the frequency,
                        severity, boldness, and effectiveness of attacks
                        deliberately initiated by the robot against its
                        opponent. If a robot appears to have accidentally
                        attacked an opponent, that act will not be considered
                        aggression.{" "}
                      </li>
                      <li>
                        Control: Control means a robot is able to attack an
                        opponent at its weakest point, use its weapons in the
                        most effective way, and minimize the damage caused by
                        the opponent or its weapons.{" "}
                      </li>
                      <li>
                        Damage: Through deliberate action, a robot either
                        directly or indirectly reduces the functionality,
                        effectiveness or defensibility of an opponent.{" "}
                      </li>
                      <li>
                        Strategy: The robot exhibits a combat plan that exploits
                        the robot's strengths against the weaknesses of its
                        opponent. Strategy is also defined as a robot exhibiting
                        a deliberate defense plan that guards its weaknesses
                        against the strengths of the opponent. NOTE:
                        Qualification of a robot to the next round is subjective
                        and totally on the decision of the judges.{" "}
                      </li>
                    </ul>
                  </li>
                  <li>
                    In case the match is stopped by a team member due to
                    malfunctioning of a combat robot, the stoppage will be
                    considered as a forfeit from the match and the opponent will
                    be declared victorious. This is called forfeiting. Once a
                    team forfeits, both the teams must stop their robot and
                    weapon, failing to do so may lead to disqualification.
                  </li>
                </ul>
                <br /> <br />{" "}
                <strong>
                  At all times the judges decision will remain final. All the
                  teams must adhere to the same. The organizing team reserves
                  the right to amend the rules and rounds on the day of the
                  event. Final say will be of the organizing team and organizing
                  university/institutes management. This will be informed to the
                  teams, then and there.
                </strong>
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              display="flex"
              marginTop={2}
            >
              <Typography varient="h1" fontSize="3rem" align="justify">
                Safety Rules
              </Typography>
            </Grid>
            <Divider />
            <Grid
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              display="flex"
              marginTop={2}
            >
              <Typography align="justify">
                Compliance with all event rules is mandatory. It is expected
                that competitors stay within the rules and procedures of their
                own accord and do not require constant policing:
                <br />
                <br />
                <ul>
                  <li>
                    Special care should be taken to protect the on-board
                    batteries and pneumatics, robots without proper protection
                    will not be allowed to compete.
                  </li>
                  <li>
                    If you have a robot or weapon design that does not fit in
                    this ruleset (even having some elements that are not
                    mentioned as allowed/ disallowed in this ruleset) or is in
                    some way ambiguous, please contact organizing team or
                    university/institute at the earliest. Safe innovation is
                    always encouraged, but surprising the organizers with your
                    brilliant exploitation of a loophole may cause your robot to
                    be disqualified before it even gets to fight in the matches.
                  </li>
                  <li>
                    Each event has safety inspections. Your team will be allowed
                    to compete at the sole discretion of organizing team and
                    organizing university/institute authorities, to whom as a
                    builder you are obligated to disclose all operating
                    principles and potential dangers to the inspection staff.
                  </li>
                  <li>
                    Proper activation and deactivation of robots is critical.
                    Robots must only be activated in the arena, testing areas,
                    or with expressed consent of the event coordinators.
                  </li>
                  <li>
                    It is expected that builders will follow all basic safety
                    practices such as shoes, gloves and goggles when operating
                    any machinery/power tools. The use of welders, grinders and
                    other equipment that may produce smoke, debris or other
                    harmful substances is only permitted in dedicated workshop
                    areas.
                  </li>
                </ul>
                <br />
                <strong>
                  Please take care of yourself and others around you. Any
                  deviation from safety protocol shall lead to disqualification
                  of teams
                </strong>
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              display="flex"
              marginTop={2}
            >
              <Typography varient="h1" fontSize="3rem" align="justify">
                In case of Accidents
              </Typography>
            </Grid>
            <Divider />
            <Grid
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              display="flex"
              marginTop={2}
            >
              <Typography align="justify">
                <ul>
                  <li>
                    1. In the case of any accident/emergency, the organizing
                    team must be notified immediately.
                  </li>
                  <li>
                    2. The organizing team will take steps to mitigate the
                    issue, please wait for the same. Please hold in such a
                    situation for the help to arrive from the health center.
                  </li>
                </ul>
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              display="flex"
              marginTop={2}
            >
              <Typography varient="h1" fontSize="3rem" align="justify">
                Safety and Violence
              </Typography>
            </Grid>
            <Divider />
            <Grid
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              display="flex"
              marginTop={2}
            >
              <Typography align="justify">
                <ul>
                  <li>
                    Any kind of physical violence or verbal abuse or argument
                    between teams or against the organizing team and organizing
                    university/institute management will not be tolerated.
                    Anyone found in violation of this shall be warned and
                    disqualified from participation in the competition.
                  </li>
                  <li>
                    All the work on the robots must be done in the pits (i.e.
                    the place provided by the organizing team). Power tools also
                    must be used in the place allotted for the same
                    specifically. Care must be taken care by the teams to ensure
                    the safety of themselves, and the public. Anyone found in
                    violation of this shall be warned and disqualified from
                    participation in the competition.
                  </li>
                  <li>
                    Violence, harassment and misbehaving against any female
                    member within or outside team, organizing team or organizing
                    university/institute is strictly prohibited and strict
                    actions will be taken if found guilty.
                  </li>
                  <li>
                    In the aforementioned points, there will be a maximum of one
                    warning per team before disqualification.
                  </li>
                </ul>
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              display="flex"
              marginTop={2}
            >
              <Typography varient="h1" fontSize="3rem" align="justify">
                Disclaimer
              </Typography>
            </Grid>
            <Divider />
            <Grid
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              display="flex"
              marginTop={2}
            >
              <Typography align="justify">
                <ul>
                  <li>
                    Organizing teams and organizing university/institute is not
                    liable for any loss of items of the team.
                  </li>
                  <li>
                    If any damage is done to the organizing university/institute
                    and organizing team's properties then the team will be held
                    responsible and appropriate actions will be taken against
                    them.
                  </li>
                </ul>
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              display="flex"
              marginTop={2}
            >
              <Typography varient="h1" fontSize="3rem" align="justify">
                Event Specific Terminology
              </Typography>
            </Grid>
            <Divider />
            <Grid
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              display="flex"
              marginTop={2}
            >
              <Typography align="justify">
                <strong>Disabled</strong>
                <br />A robot is not functioning correctly due to either an
                internal malfunction, or contact with the opposing robot.
                <br />
                <br />
                <strong>Disqualification</strong>
                <br />A robot is no longer permitted to compete in the current
                RoboWars Tournament. <br />
                <br />
                <strong>Immobilized</strong>
                <br />
                In the judge's opinion, a robot is not responsive for a
                specified period of time. <br />
                <br />
                <strong>Knockout</strong>
                <br />
                Occurs when the attack or deliberate actions of one robot causes
                its opponent to become immobilized. <br />
                <br />
                <strong>Lifting</strong>
                <br /> Occurs when one robot controls an opponent's
                translational motion by lifting the drive mechanism of the
                opponent off of the Arena floor. <br />
                <br />
                <strong>No contact</strong>
                <br />
                Occurs when neither robot makes contact with each other for a
                specified period of time. <br />
                <br />
                <strong>Pinning</strong>
                <br />
                Occurs when one robot, through sheer force, holds an opponent
                stationary in order to immobilize it. <br />
                <br />
                <strong>Radio Interference</strong>
                <br />
                Refers to the situation where at least one robot becomes
                non-responsive or non-controllable due to the effect of the
                other robot's remote-control signal. <br />
                <br />
                <strong>Non-Responsive</strong>
                <br />
                In a referee's opinion, the robot should not display some kind
                of translational movement along the Arena floor. <br />
                <br />
                <strong>Restart</strong>
                <br /> Occurs after a Fault or a Timeout has been declared and
                the competing robots are ready to continue. <br />
                <br />
                <strong>Stuck</strong>
                <br />
                A robot is hung-up on a part of the Arena, an Arena Hazard or an
                opponent, such that it is effectively non-responsive. <br />
                <br />
                <strong>Tap-Out</strong>
                <br />
                Occurs when a robot's operators decide that they no longer want
                to continue the match, and concede the win to the opposing team.{" "}
                <br />
                <br />
                <strong>Technical Knockout</strong>
                <br />
                Occurs when a robot wins due to immobilization of its opponent
                even though, in the judges' opinion, no action of the winning
                robot causes the opponent's immobilization. <br />
                <br />
                <strong>Timeout</strong>
                <br />A temporary halting of a match. Timeouts are usually
                called to separate robots, but should be called for other
                reasons as well. <br />
                <br />
                <strong>Cluster Bot / Multiverse</strong>
                <br />
                Participating teams having multiple bots can compete in their
                combined weight category, i.e., for example two 30kg bots of the
                same team can compete in the 60kg weight category.
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              display="flex"
              marginTop={2}
            >
              <Typography varient="h1" fontSize="3rem" align="justify">
                Guidelines for Matches
              </Typography>
            </Grid>
            <Divider />
            <Grid
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              display="flex"
              marginTop={2}
            >
              <Typography align="justify">
                <ul>
                  <li>The judge's decision is the last decision.</li>
                  <li>
                    If the lights are destroyed or damaged, the match will be
                    instantly paused, and the judges will take over.
                  </li>
                  <li>The corners will be chosen through a coin toss</li>
                  <li>
                    You need to take permission from coordinating or
                    organization team to test your bot in arena
                  </li>
                  <li>
                    You cannot test your bot in the arena without appropriate
                    approval of organizing team and judges.
                  </li>
                  <li>
                    At least One of the members of the organizing team should be
                    present while testing your bot.
                  </li>
                  <li>No weapon testing to be done inside the pits</li>
                  <li>
                    Final weight check will be done just before putting the bot
                    in the arena. No team member can touch the bot after the
                    official weighin.
                  </li>
                  <li>
                    If any changes are made to the bot, the judges should be
                    notified before the match.
                  </li>
                  <li>
                    <strong>Marking scheme:</strong>
                    <ul>
                      <li>Controlling/strategy of the robot - 1 point</li>
                      <li>Agression - 1 point</li>
                      <li>Damage - 2 points</li>
                      <li>Corner pin for wedge bots - 1 point</li>
                    </ul>
                  </li>
                </ul>
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              display="flex"
              marginTop={2}
            >
              <Typography varient="h1" fontSize="3rem" align="justify">
                Arena Details
              </Typography>
            </Grid>
            <Divider />
            <Grid
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              display="flex"
              marginTop={2}
            >
              <Typography align="justify">
                <ul>
                  <li>
                    Arena should be covered with thick polycarbonate sheets from
                    all sides except the base plate.
                  </li>
                  <li>Base Plate should made out of Steel.</li>
                  <li>
                    All safety standards should be maintained at any point of
                    time.
                  </li>
                  <li>Please insert your arena specifications here.</li>
                </ul>
              </Typography>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={2} />
      </Grid>

      {/* Mobile Varient*/}

      <Grid
        container
        marginTop={2}
        alignItems="center"
        sx={{ display: { xs: "flex", md: "none" } }}
      >
        <Grid item xs={12}>
          <Paper
            sx={{
              padding: 2,
              margin: "auto",
              maxWidth: 900,
              flexGrow: 1,
              backgroundColor: (theme) =>
                theme.palette.mode === "dark" ? "#1A2027" : "#fff",
            }}
          >
            <Grid container alignItems="center" justifyContent="center">
              <Grid
                item
                xs={12}
                justifyContent="center"
                alignItems="center"
                display="flex"
                marginTop={2}
              >
                <Typography varient="h1" fontSize="2rem" align="justify">
                  Design specifications
                </Typography>
              </Grid>
              <Divider />
              <Grid
                item
                xs={12}
                justifyContent="center"
                alignItems="center"
                display="flex"
                marginTop={2}
              >
                <TableContainer component={Paper}>
                  <Table
                    sx={{ minWidth: 650 }}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Class</TableCell>
                        <TableCell align="center">Weight</TableCell>
                        <TableCell align="center">Dimensions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow
                          key={row.name}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row" align="center">
                            {row.name}
                          </TableCell>
                          <TableCell align="center">{row.weight} kg</TableCell>
                          <TableCell align="center">{row.dimensions}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid
                item
                xs={12}
                justifyContent="center"
                alignItems="center"
                display="flex"
                marginTop={2}
              >
                <Typography align="justify">
                  All categories permits all kinds of weapons specified in the
                  rulebook. The machine should not exceed the above
                  specifications. Any external device used to control the
                  machine is not included in the dimension limits. All the
                  batteries should be on-board, only the weight of the remote
                  controller will not be included. However an extra tolerance of
                  2cm in dimensions is allowed. The weight of the bot will be
                  checked before and/or after every match. A bot can be in a
                  "Cluster Bot" formation. Each bot must meet the requirements
                  described in this problem statement. The total weight of all
                  the bots must not cross the limits in the above point.
                  Participating teams having multiple bots can compete in their
                  combined weight category, i.e., for example two 30kg bots of
                  the same team can compete in the 60kg weight category
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                justifyContent="center"
                alignItems="center"
                display="flex"
                marginTop={2}
              >
                <Typography varient="h1" fontSize="2rem" align="justify">
                  Mobility
                </Typography>
              </Grid>
              <Divider />
              <Grid
                item
                xs={12}
                justifyContent="center"
                alignItems="center"
                display="flex"
                marginTop={2}
              >
                <Typography align="justify">
                  All robots must have easily visible and controlled mobility in
                  order to compete. Methods of mobility include:
                  <ul>
                    <li>Rolling (wheels, tracks or the whole robot).</li>
                    <li>
                      Non-wheeled: Non-wheeled robots have no rolling elements
                      in contact with the floor and have no continuous rolling
                      or Cam Operated motion in contact with the floor, either
                      directly or via a linkage, but are not true walkers.
                      Motion is continuous if continuous operation of the drive
                      motor(s) produces continuous motion of the robot.
                      Linear-actuated legs and novel non wheeled drive systems
                      are allowed under this category.
                    </li>
                    <li>
                      Jumping and hopping is allowed (manually operated).
                      However, the maximum height of any part of the machine
                      should not exceed 6ft during any stage of its
                      jumping/hopping and any damage caused due to its mechanism
                      as well as the arena is responsible to the team.
                    </li>
                  </ul>
                  Mobility methods that are <strong>NOT</strong> allowed:
                  <ul>
                    <li>
                      Flying(using airfoil,helium balloons, etc) are not
                      allowed.{" "}
                    </li>
                    <li>
                      Robots should not secure themselves on the ring by using
                      suction cups, etc
                    </li>
                  </ul>
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                justifyContent="center"
                alignItems="center"
                display="flex"
                marginTop={2}
              >
                <Typography varient="h1" fontSize="2rem" align="justify">
                  Robot Control Requirements
                </Typography>
              </Grid>
              <Divider />
              <Grid
                item
                xs={12}
                justifyContent="center"
                alignItems="center"
                display="flex"
                marginTop={2}
              >
                <Typography align="justify">
                  <ul>
                    <li>
                      All robots must be controlled through
                      <strong>wireless remote only</strong>, and all power
                      supply must be on-board only.
                      <strong>
                        No external connections (wired) will be allowed during
                        matches.
                      </strong>
                    </li>
                    <li>
                      Control must be exhibited over all of its functions and
                      positions at all times and should be displayed even during
                      matches at the say-so of the judges/organizing team.
                    </li>
                    <li>
                      Although Autonomous functions within the bot are
                      acceptable, but the controller must be able to remotely
                      disable or override those functions at any time. Also note
                      that any damage due to this function is a responsibility
                      of the team, and there must compulsorily be a{" "}
                      <strong>manual emergency stop</strong>
                      (E-stop) function that should be controlled from the radio
                      controller to manually override the autonomous function in
                      case of emergency. Such an emergency shall be declared by
                      the judge.
                    </li>
                    <li>
                      The transmitter-receiver should be able to work through
                      polycarbonate sheets, metal bars and barriers. Remote
                      Controllers with such facilities will only be allowed.
                    </li>
                    <li>
                      The team must have at least four frequency wireless remote
                      control circuits or two dual control circuits which may be
                      interchanged before the start of the match to avoid
                      frequency interference with other teams. The case of any
                      interference in the wireless system will not be considered
                      for rematch or results.
                    </li>
                    <li>
                      Remote control systems available in the market can be
                      used, While nonstandard or self-made remote control
                      systems must first be approved by the organizers and
                      judges.
                    </li>
                    <li>
                      Team should pair up the wireless remote with the robot
                      before putting it into the arena. No extra time will be
                      provided for pairing of transmitter and receiver once the
                      robots are put inside the arena, and not connecting it
                      prior to that may lead to Disqualification.
                    </li>
                  </ul>
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                justifyContent="center"
                alignItems="center"
                display="flex"
                marginTop={2}
              >
                <Typography varient="h1" fontSize="2rem" align="justify">
                  Battery and Power
                </Typography>
              </Grid>
              <Divider />
              <Grid
                item
                xs={12}
                justifyContent="center"
                alignItems="center"
                display="flex"
                marginTop={2}
              >
                <Typography align="justify">
                  <ul>
                    <li>
                      The robot should be powered electrically only. Use of IC
                      engines in any form is not allowed. On board batteries
                      must be sealed and immobilized-electrolyte types of
                      batteries should be used (such as gel cells, lithium,
                      NiCad, NiMH, or dry cells).{" "}
                    </li>
                    <li>
                      The electric voltage between 2 points anywhere in the
                      machine should not be more than 48V DC at any point of
                      time. Participants will have to bring their own converters
                      for standard power supply according to Indian standards.
                      All efforts must be made to protect battery terminals from
                      a direct short and causing a battery fire, failure to do
                      so will cause direct disqualification. In case of any
                      emergency, the participants must stay calm and stay in the
                      respective corners, the organizers will only be allowed
                      inside the arena.
                    </li>
                    <li>
                      Use of damaged, non-leak proof batteries may lead to
                      disqualification.{" "}
                    </li>
                    <li>
                      Special care should be taken to protect the on-board
                      batteries. If judges find the battery is not properly
                      protected, then the team will be disqualified immediately.{" "}
                    </li>
                    <li>
                      Change of battery will not be allowed during the match.
                    </li>
                    <li>
                      Robots with only <strong>on board batteries</strong> are
                      allowed.
                    </li>
                    <li>
                      It is suggested to have extra batteries ready and charged
                      up during the competition so that on advancing to the next
                      round, there is no delay. If teams don't show up on
                      allotted slots within the specified time limit, they will
                      be disqualified.
                    </li>
                  </ul>
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                justifyContent="center"
                alignItems="center"
                display="flex"
                marginTop={2}
              >
                <Typography varient="h1" fontSize="2rem" align="justify">
                  Pneumatics
                </Typography>
              </Grid>
              <Divider />
              <Grid
                item
                xs={12}
                justifyContent="center"
                alignItems="center"
                display="flex"
                marginTop={2}
              >
                <Typography align="justify">
                  <ul>
                    <li>
                      Robots can use pressurized non-flammable gasses to actuate
                      pneumatic devices. Maximum allowed outlet nozzle pressure
                      is 50 bar. The storage tank and pressure used by the team
                      needs to be certified and teams using pneumatics are
                      required to produce the Safety and Security letter at the
                      Registration desk at the venue. Failing to do so will lead
                      to direct disqualification.{" "}
                    </li>
                    <li>
                      Participants must be able to indicate the used pressure
                      with an integrated or temporarily fitted pressure gauge.
                      Also there should be provision to check the cylinder
                      pressure on the bot.{" "}
                    </li>
                    <li>
                      The maximum pressure in the cylinder should not exceed the
                      rated pressure at any point of time. All the pneumatic
                      components must be rated at least value of maximum
                      pressure.{" "}
                    </li>
                    <li>
                      You must have a safe way of refilling the system and
                      determining the on board pressure.
                    </li>
                    <li>
                      All pneumatic components on board a robot must be securely
                      mounted. Care must be taken while mounting the pressure
                      vessel and armor to ensure that, if ruptured it will not
                      spread out of the robot. The terms pressure vessel bottle,
                      and source tank are used interchangeably.{" "}
                    </li>
                    <li>
                      Entire pneumatic setup should be on board, no external
                      input (from outside the arena) should be given to the
                      robot for functioning of its pneumatic system.
                    </li>
                  </ul>
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                justifyContent="center"
                alignItems="center"
                display="flex"
                marginTop={2}
              >
                <Typography varient="h1" fontSize="2rem" align="justify">
                  Weapon Systems
                </Typography>
              </Grid>
              <Divider />
              <Grid
                item
                xs={12}
                justifyContent="center"
                alignItems="center"
                display="flex"
                marginTop={2}
              >
                <Typography align="justify">
                  Robots can have any kind of magnetic weapons, cutters,
                  flippers, saws, lifting devices, spinning hammers etc. as
                  weapons(if they qualify the criteria mentioned below). <br />{" "}
                  <br />
                  All the weapons must have a safety cover over any sharp edges,
                  all weapons should have a <strong>weapon lock</strong> to
                  restrict the motion of the weapon when the bot is not in the
                  arena and it should be removed only when the bot is ready on
                  the arena. <br /> <br />
                  Following weapons are exceptions and should not be used:All
                  robots must have easily visible and controlled mobility in
                  order to compete. Methods of mobility include: <br /> <br />
                  <ul>
                    <li>
                      Solid and Liquid projectiles (bullets, foam, liquefied
                      gasses,water, flame thrower){" "}
                    </li>
                    <li>
                      Weapons causing invisible damage (Electrical weapon, RF
                      jamming weapons and others)
                    </li>
                    <li>
                      Spinning Weapons:
                      <ul>
                        <li>
                          Spinning weapons must come to a halt within 60 seconds
                          of the power being removed using a self-contained
                          braking-system.
                        </li>
                        <li>
                          Spinning weapons must only be tested in the arena,
                          failing to adhere to this rule will lead to
                          disqualification of the robot.
                        </li>
                      </ul>
                    </li>
                    <li>
                      Spring-Loaded or flywheels:
                      <ul>
                        <li>
                          The spring must always be free and unloaded, only
                          during the match the spring must be loaded inside the
                          arena. All testing must be done in the arena only
                          under the supervision of the organizing team.
                        </li>
                        <li>
                          All springs, flywheels, and similar kinetic energy
                          storing devices must be controllable at all times. Any
                          intended/unintended movement outside the arena or near
                          the public may lead to disqualification of the team
                          under safety violation.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                justifyContent="center"
                alignItems="center"
                display="flex"
                marginTop={2}
              >
                <Typography varient="h1" fontSize="2rem" align="justify">
                  Team Specifications
                </Typography>
              </Grid>
              <Divider />
              <Grid
                item
                xs={12}
                justifyContent="center"
                alignItems="center"
                display="flex"
                marginTop={2}
              >
                <Typography align="justify">
                  <ul>
                    <li>
                      A team can consist of a maximum of 6 participants per bot.
                      These participants can be from the same or different
                      institutes.
                    </li>
                    <li>
                      Team Name: Every team must have a unique name. The
                      organizing team reserves the right to reject entries from
                      any team whose name it deems inappropriate, offensive or
                      conflicting. Organizers must be notified if a team's name
                      has been changed.
                    </li>
                    <li>
                      Team Representative: Each team must specify their Team
                      Representative (Leader) at the time of registration on the
                      website. All important communications between organizing
                      team and the registered teams will be done through their
                      team representative. The team representative must submit
                      valid contact details (phone no, email ID, etc) at the
                      time of registration.
                    </li>
                    <li>
                      Robot's name cannot be changed during the whole course of
                      the event. Any changes in the design should be informed to
                      the coordinators/organizing team.
                    </li>
                    <li>
                      Robot name and photo should be submitted during the
                      registrations. The submitted details will be cross-checked
                      anytime during the entire course of the event.
                    </li>
                    <li>
                      If a team has more than one robot in a single category,
                      they must be visually distinguishable, this shall be
                      checked at the start of the tournament, and any deviation
                      from the original shall be punished with disqualification
                      (Discretion of the judges).
                    </li>
                    <li>
                      If a participating team is entering two bots in the same
                      category, it's required that each bot has a distinct
                      pilot, the same pilot cannot operate both bots.
                    </li>
                    <li>
                      In the event that two bots belong to the same
                      participating team, it is imperative that their components
                      and parts exhibit noticeable distinctions. Failure to
                      achieve this can result in potential disqualification.
                    </li>
                    <li>
                      All participating teams must present an abstract of the
                      bot along with the bot that they are competing with, one,
                      two or many.
                    </li>
                    <li>
                      All bots will be inspected according to the abstract
                      submitted by the team and it should meet the
                      specifications produced by the respective team.
                    </li>
                    <li>
                      All participating teams must have their bots completely
                      assembled 30 mins prior to the start of the competition
                    </li>
                  </ul>
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                justifyContent="center"
                alignItems="center"
                display="flex"
                marginTop={2}
              >
                <Typography varient="h1" fontSize="2rem" align="justify">
                  Tournament Profile
                </Typography>
              </Grid>
              <Divider />
              <Grid
                item
                xs={12}
                justifyContent="center"
                alignItems="center"
                display="flex"
                marginTop={2}
              >
                <Typography align="justify">
                  <ul>
                    <li>
                      Double Elimination form of tournament will be followed in
                      the competition.
                    </li>
                    <li>
                      The fixtures will be drawn by the judges in the presence
                      of the organizing team to ensure a fair process. This will
                      be done using a chit system to assign opponents in the
                      fixtures.
                    </li>
                    <li>
                      Fixtures will not be changed under any circumstances. Any
                      request to do so will not be entertained by the organizing
                      team.
                    </li>
                  </ul>
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                justifyContent="center"
                alignItems="center"
                display="flex"
                marginTop={2}
              >
                <Typography varient="h1" fontSize="2rem" align="justify">
                  Match Frequency
                </Typography>
              </Grid>
              <Divider />
              <Grid
                item
                xs={12}
                justifyContent="center"
                alignItems="center"
                display="flex"
                marginTop={2}
              >
                <Typography align="justify">
                  Each team shall be given 30 minutes between matches
                  (Organizers reserve the right to change this duration on the
                  day of the competition).This time is calculated from the time
                  the robot leaves the post match staging area. If the team
                  fails to return, with the robot ready, to the prematch staging
                  area when called after the allotted time, the team will be
                  forced to forfeit as per the discretion of the judges and
                  organizers. <br /> <br />
                  Matches will be 3 minutes of active fight time exclusive of
                  any time-outs. Hence, it is not binding but advisable to keep
                  battery capacity, power usage and machine defenses such that
                  it should sustain a 3 minute fight. <br /> <br />
                  The team members are not allowed inside the arena between the
                  matches, if a bot stops working it will be declared immobile.
                  Only if judges find that bots are stuck together and have no
                  movement from any side then we will give 20-30 sec to let them
                  separate by themselves under the say so and supervision of a
                  judge/organizing team. <br /> <br />
                  It is recommended that any routine maintenance (i.e. battery
                  charging) should be capable of being performed well within
                  this time period, or backup should be kept. In extreme cases
                  the 30 minutes time period may be lengthened at the discretion
                  of the judges and event organizers. The weight of the bot will
                  be checked before and/or after every match.
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                justifyContent="center"
                alignItems="center"
                display="flex"
                marginTop={2}
              >
                <Typography varient="h1" fontSize="2rem" align="justify">
                  Pre-Match Protocols
                </Typography>
              </Grid>
              <Divider />
              <Grid
                item
                xs={12}
                justifyContent="center"
                alignItems="center"
                display="flex"
                marginTop={2}
              >
                <Typography align="justify">
                  <ul>
                    <li>
                      Bots safety will be checked 1 night prior to the
                      competition. This will be verified again before the match
                      starts.{" "}
                    </li>
                    <li>
                      Robot should be ready, functional and near the arena 15
                      mins prior to the final call for the match.{" "}
                    </li>
                    <li>
                      Participating teams must adhere to a strict 0% weight
                      tolerance, ensuring that the bot's total weight remains
                      within the specified limits at all times.
                    </li>
                  </ul>
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                justifyContent="center"
                alignItems="center"
                display="flex"
                marginTop={2}
              >
                <Typography varient="h1" fontSize="2rem" align="justify">
                  Protocols during Match
                </Typography>
              </Grid>
              <Divider />
              <Grid
                item
                xs={12}
                justifyContent="center"
                alignItems="center"
                display="flex"
                marginTop={2}
              >
                <Typography align="justify">
                  <ul>
                    <li>
                      During the match, a maximum of 2 people will be allowed
                      near the arena on the robot driver's stage. Rest of the
                      team must stay away from the arena and in the proper place
                      designated by the organizing team.{" "}
                    </li>
                    <li>
                      Team members must not enter the arena under any
                      circumstances. The organizing team will handle any mishap
                      and only after the organizing team ensures the safety,
                      teams can be allowed into the arena to retrieve their
                      robots. Failing to follow this will lead to direct
                      elimination irrespective of the match outcome (discretion
                      of organizers){" "}
                    </li>
                    <li>
                      {" "}
                      The Weapon and the Robot should start moving only after
                      the countdown is fully completed. If it is started in
                      between the countdown, the judges and the organizing team
                      has the right to restart the match or disqualify the team.
                    </li>
                  </ul>
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                justifyContent="center"
                alignItems="center"
                display="flex"
                marginTop={2}
              >
                <Typography varient="h1" fontSize="2rem" align="justify">
                  Criteria for Victory
                </Typography>
              </Grid>
              <Divider />
              <Grid
                item
                xs={12}
                justifyContent="center"
                alignItems="center"
                display="flex"
                marginTop={2}
              >
                <Typography align="justify">
                  <ul>
                    <li>
                      A robot is declared victorious if its opponent is
                      immobilized.{" "}
                    </li>
                    <li>
                      A robot will be declared immobile if it can not display
                      linear motion of at least one inch in a timed period of 10
                      seconds or as decided by the judges. A bot with one side
                      of its drivetrain disabled will not be counted out if it
                      can demonstrate some degree of controlled movement. In
                      such a case, if appealed by the opposing team and the
                      judges deem it necessary, the match should be halted to
                      check the controlled movement of the robot, if the control
                      of the bot is satisfactory to the judges, the match will
                      continue or else the match will be ended and the winner
                      declared.{" "}
                    </li>
                    <li>
                      A robot that is deemed unsafe by the judges after the
                      match has begun will be disqualified and therefore
                      declared the loser. The match will be immediately halted
                      and the opponent will be awarded a win.{" "}
                    </li>
                    <li>
                      If a robot is thrown out of the Arena, and is not able to
                      come back in, the match will stop immediately, and the
                      robot still inside the Arena will automatically be
                      declared as the winner. If a robot is immobilized on the
                      side of the arena, the weapon must be stopped immediately.
                      Causing damage to the arena intentionally shall be
                      penalized.{" "}
                    </li>
                    <li>
                      Robots can not win by pinning or lifting their opponents.
                      Organizers will allow pinning or lifting for a maximum of
                      20 seconds per pin lift then the attacker robot will be
                      instructed to release the opponent. If, after being
                      instructed to do so, the attacker is able to release but
                      does not, their robot may be disqualified. If two or more
                      robots become entangled or a crushing or gripping weapon
                      is employed and are trapped within another robot, then the
                      competitors can make the timekeeper aware, the fight
                      should be stopped and the robots separated by the safest
                      means at the say so of the judges
                    </li>
                    <li>
                      Points will be given on the basis of aggression, damage,
                      control, and strategy.{" "}
                      <ul>
                        <li>
                          Aggression: Aggression is judged by the frequency,
                          severity, boldness, and effectiveness of attacks
                          deliberately initiated by the robot against its
                          opponent. If a robot appears to have accidentally
                          attacked an opponent, that act will not be considered
                          aggression.{" "}
                        </li>
                        <li>
                          Control: Control means a robot is able to attack an
                          opponent at its weakest point, use its weapons in the
                          most effective way, and minimize the damage caused by
                          the opponent or its weapons.{" "}
                        </li>
                        <li>
                          Damage: Through deliberate action, a robot either
                          directly or indirectly reduces the functionality,
                          effectiveness or defensibility of an opponent.{" "}
                        </li>
                        <li>
                          Strategy: The robot exhibits a combat plan that
                          exploits the robot's strengths against the weaknesses
                          of its opponent. Strategy is also defined as a robot
                          exhibiting a deliberate defense plan that guards its
                          weaknesses against the strengths of the opponent.
                          NOTE: Qualification of a robot to the next round is
                          subjective and totally on the decision of the judges.{" "}
                        </li>
                      </ul>
                    </li>
                    <li>
                      In case the match is stopped by a team member due to
                      malfunctioning of a combat robot, the stoppage will be
                      considered as a forfeit from the match and the opponent
                      will be declared victorious. This is called forfeiting.
                      Once a team forfeits, both the teams must stop their robot
                      and weapon, failing to do so may lead to disqualification.
                    </li>
                  </ul>
                  <br /> <br />{" "}
                  <strong>
                    At all times the judges decision will remain final. All the
                    teams must adhere to the same. The organizing team reserves
                    the right to amend the rules and rounds on the day of the
                    event. Final say will be of the organizing team and
                    organizing university/institutes management. This will be
                    informed to the teams, then and there.
                  </strong>
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                justifyContent="center"
                alignItems="center"
                display="flex"
                marginTop={2}
              >
                <Typography varient="h1" fontSize="2rem" align="justify">
                  Safety Rules
                </Typography>
              </Grid>
              <Divider />
              <Grid
                item
                xs={12}
                justifyContent="center"
                alignItems="center"
                display="flex"
                marginTop={2}
              >
                <Typography align="justify">
                  Compliance with all event rules is mandatory. It is expected
                  that competitors stay within the rules and procedures of their
                  own accord and do not require constant policing:
                  <br />
                  <br />
                  <ul>
                    <li>
                      Special care should be taken to protect the on-board
                      batteries and pneumatics, robots without proper protection
                      will not be allowed to compete.
                    </li>
                    <li>
                      If you have a robot or weapon design that does not fit in
                      this ruleset (even having some elements that are not
                      mentioned as allowed/ disallowed in this ruleset) or is in
                      some way ambiguous, please contact organizing team or
                      university/institute at the earliest. Safe innovation is
                      always encouraged, but surprising the organizers with your
                      brilliant exploitation of a loophole may cause your robot
                      to be disqualified before it even gets to fight in the
                      matches.
                    </li>
                    <li>
                      Each event has safety inspections. Your team will be
                      allowed to compete at the sole discretion of organizing
                      team and organizing university/institute authorities, to
                      whom as a builder you are obligated to disclose all
                      operating principles and potential dangers to the
                      inspection staff.
                    </li>
                    <li>
                      Proper activation and deactivation of robots is critical.
                      Robots must only be activated in the arena, testing areas,
                      or with expressed consent of the event coordinators.
                    </li>
                    <li>
                      It is expected that builders will follow all basic safety
                      practices such as shoes, gloves and goggles when operating
                      any machinery/power tools. The use of welders, grinders
                      and other equipment that may produce smoke, debris or
                      other harmful substances is only permitted in dedicated
                      workshop areas.
                    </li>
                  </ul>
                  <br />
                  <strong>
                    Please take care of yourself and others around you. Any
                    deviation from safety protocol shall lead to
                    disqualification of teams
                  </strong>
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                justifyContent="center"
                alignItems="center"
                display="flex"
                marginTop={2}
              >
                <Typography varient="h1" fontSize="2rem" align="justify">
                  In case of Accidents
                </Typography>
              </Grid>
              <Divider />
              <Grid
                item
                xs={12}
                justifyContent="center"
                alignItems="center"
                display="flex"
                marginTop={2}
              >
                <Typography align="justify">
                  <ul>
                    <li>
                      1. In the case of any accident/emergency, the organizing
                      team must be notified immediately.
                    </li>
                    <li>
                      2. The organizing team will take steps to mitigate the
                      issue, please wait for the same. Please hold in such a
                      situation for the help to arrive from the health center.
                    </li>
                  </ul>
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                justifyContent="center"
                alignItems="center"
                display="flex"
                marginTop={2}
              >
                <Typography varient="h1" fontSize="2rem" align="justify">
                  Safety and Violence
                </Typography>
              </Grid>
              <Divider />
              <Grid
                item
                xs={12}
                justifyContent="center"
                alignItems="center"
                display="flex"
                marginTop={2}
              >
                <Typography align="justify">
                  <ul>
                    <li>
                      Any kind of physical violence or verbal abuse or argument
                      between teams or against the organizing team and
                      organizing university/institute management will not be
                      tolerated. Anyone found in violation of this shall be
                      warned and disqualified from participation in the
                      competition.
                    </li>
                    <li>
                      All the work on the robots must be done in the pits (i.e.
                      the place provided by the organizing team). Power tools
                      also must be used in the place allotted for the same
                      specifically. Care must be taken care by the teams to
                      ensure the safety of themselves, and the public. Anyone
                      found in violation of this shall be warned and
                      disqualified from participation in the competition.
                    </li>
                    <li>
                      Violence, harassment and misbehaving against any female
                      member within or outside team, organizing team or
                      organizing university/institute is strictly prohibited and
                      strict actions will be taken if found guilty.
                    </li>
                    <li>
                      In the aforementioned points, there will be a maximum of
                      one warning per team before disqualification.
                    </li>
                  </ul>
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                justifyContent="center"
                alignItems="center"
                display="flex"
                marginTop={2}
              >
                <Typography varient="h1" fontSize="2rem" align="justify">
                  Disclaimer
                </Typography>
              </Grid>
              <Divider />
              <Grid
                item
                xs={12}
                justifyContent="center"
                alignItems="center"
                display="flex"
                marginTop={2}
              >
                <Typography align="justify">
                  <ul>
                    <li>
                      Organizing teams and organizing university/institute is
                      not liable for any loss of items of the team.
                    </li>
                    <li>
                      If any damage is done to the organizing
                      university/institute and organizing team's properties then
                      the team will be held responsible and appropriate actions
                      will be taken against them.
                    </li>
                  </ul>
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                justifyContent="center"
                alignItems="center"
                display="flex"
                marginTop={2}
              >
                <Typography varient="h1" fontSize="2rem" align="justify">
                  Event Specific Terminology
                </Typography>
              </Grid>
              <Divider />
              <Grid
                item
                xs={12}
                justifyContent="center"
                alignItems="center"
                display="flex"
                marginTop={2}
              >
                <Typography align="justify">
                  <strong>Disabled</strong>
                  <br />A robot is not functioning correctly due to either an
                  internal malfunction, or contact with the opposing robot.
                  <br />
                  <br />
                  <strong>Disqualification</strong>
                  <br />A robot is no longer permitted to compete in the current
                  RoboWars Tournament. <br />
                  <br />
                  <strong>Immobilized</strong>
                  <br />
                  In the judge's opinion, a robot is not responsive for a
                  specified period of time. <br />
                  <br />
                  <strong>Knockout</strong>
                  <br />
                  Occurs when the attack or deliberate actions of one robot
                  causes its opponent to become immobilized. <br />
                  <br />
                  <strong>Lifting</strong>
                  <br /> Occurs when one robot controls an opponent's
                  translational motion by lifting the drive mechanism of the
                  opponent off of the Arena floor. <br />
                  <br />
                  <strong>No contact</strong>
                  <br />
                  Occurs when neither robot makes contact with each other for a
                  specified period of time. <br />
                  <br />
                  <strong>Pinning</strong>
                  <br />
                  Occurs when one robot, through sheer force, holds an opponent
                  stationary in order to immobilize it. <br />
                  <br />
                  <strong>Radio Interference</strong>
                  <br />
                  Refers to the situation where at least one robot becomes
                  non-responsive or non-controllable due to the effect of the
                  other robot's remote-control signal. <br />
                  <br />
                  <strong>Non-Responsive</strong>
                  <br />
                  In a referee's opinion, the robot should not display some kind
                  of translational movement along the Arena floor. <br />
                  <br />
                  <strong>Restart</strong>
                  <br /> Occurs after a Fault or a Timeout has been declared and
                  the competing robots are ready to continue. <br />
                  <br />
                  <strong>Stuck</strong>
                  <br />A robot is hung-up on a part of the Arena, an Arena
                  Hazard or an opponent, such that it is effectively
                  non-responsive. <br />
                  <br />
                  <strong>Tap-Out</strong>
                  <br />
                  Occurs when a robot's operators decide that they no longer
                  want to continue the match, and concede the win to the
                  opposing team. <br />
                  <br />
                  <strong>Technical Knockout</strong>
                  <br />
                  Occurs when a robot wins due to immobilization of its opponent
                  even though, in the judges' opinion, no action of the winning
                  robot causes the opponent's immobilization. <br />
                  <br />
                  <strong>Timeout</strong>
                  <br />A temporary halting of a match. Timeouts are usually
                  called to separate robots, but should be called for other
                  reasons as well. <br />
                  <br />
                  <strong>Cluster Bot / Multiverse</strong>
                  <br />
                  Participating teams having multiple bots can compete in their
                  combined weight category, i.e., for example two 30kg bots of
                  the same team can compete in the 60kg weight category.
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                justifyContent="center"
                alignItems="center"
                display="flex"
                marginTop={2}
              >
                <Typography varient="h1" fontSize="2rem" align="justify">
                  Guidelines for Matches
                </Typography>
              </Grid>
              <Divider />
              <Grid
                item
                xs={12}
                justifyContent="center"
                alignItems="center"
                display="flex"
                marginTop={2}
              >
                <Typography align="justify">
                  <ul>
                    <li>The judge's decision is the last decision.</li>
                    <li>
                      If the lights are destroyed or damaged, the match will be
                      instantly paused, and the judges will take over.
                    </li>
                    <li>The corners will be chosen through a coin toss</li>
                    <li>
                      You need to take permission from coordinating or
                      organization team to test your bot in arena
                    </li>
                    <li>
                      You cannot test your bot in the arena without appropriate
                      approval of organizing team and judges.
                    </li>
                    <li>
                      At least One of the members of the organizing team should
                      be present while testing your bot.
                    </li>
                    <li>No weapon testing to be done inside the pits</li>
                    <li>
                      Final weight check will be done just before putting the
                      bot in the arena. No team member can touch the bot after
                      the official weighin.
                    </li>
                    <li>
                      If any changes are made to the bot, the judges should be
                      notified before the match.
                    </li>
                    <li>
                      <strong>Marking scheme:</strong>
                      <ul>
                        <li>Controlling/strategy of the robot - 1 point</li>
                        <li>Agression - 1 point</li>
                        <li>Damage - 2 points</li>
                        <li>Corner pin for wedge bots - 1 point</li>
                      </ul>
                    </li>
                  </ul>
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                justifyContent="center"
                alignItems="center"
                display="flex"
                marginTop={2}
              >
                <Typography varient="h1" fontSize="2rem" align="justify">
                  Arena Details
                </Typography>
              </Grid>
              <Divider />
              <Grid
                item
                xs={12}
                justifyContent="center"
                alignItems="center"
                display="flex"
                marginTop={2}
              >
                <Typography align="justify">
                  <ul>
                    <li>
                      Arena should be covered with thick polycarbonate sheets
                      from all sides except the base plate.
                    </li>
                    <li>Base Plate should made out of Steel.</li>
                    <li>
                      All safety standards should be maintained at any point of
                      time.
                    </li>
                    <li>Please insert your arena specifications here.</li>
                  </ul>
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Rulebook;
